import React from 'react';
import {
  Checkbox as MuiCheckbox,
  CheckboxProps as MuiCheckboxProps,
  FormControlLabel,
  withStyles,
} from '@material-ui/core';
import { NodeWithChildren } from '../../types';
import { theme } from 'styles/styled-components';

export type LabelPlacement = 'start' | 'end';

export interface CheckboxProps extends NodeWithChildren, MuiCheckboxProps {
  label?: string;
  labelPlacement?: LabelPlacement;
  name?: string;
}

const StyledFormControl = withStyles({
  root: {
    userSelect: 'none',
    height: theme.button.size.small,
  },
})(FormControlLabel);

const DefaultColorCheckbox = withStyles({
  checked: {
    color: theme.color.default,
  },
})((props: CheckboxProps) => <MuiCheckbox color="default" {...props} />);

export const Checkbox = (props: CheckboxProps) => {
  const defaultProps: MuiCheckboxProps = {
    size: 'small',
  };
  const chkProps: MuiCheckboxProps = {
    ...defaultProps,
    ...props,
  };
  const CheckboxEl =
    !props.color || props.color === 'default'
      ? DefaultColorCheckbox
      : MuiCheckbox;

  return (
    <StyledFormControl
      control={<CheckboxEl {...chkProps} />}
      color={props.color}
      value={props.value}
      label={props.children || props.label}
      labelPlacement={props.labelPlacement || 'end'}
    />
  );
};

export default Checkbox;
