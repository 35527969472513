import { StyleableComponent } from '../../../types';
import React from 'react';
import styled from 'styles/styled-components';

import Link from '../../Link';

export interface CardImageProps extends StyleableComponent {
  thumbnail: string;
  fullImage?: string;
  height?: string;
}

const ImageContainer = styled.div`
  height: ${({ height }: CardImageProps) => height || '300px'};
  position: relative;
  overflow: hidden;

  &:after {
    content: '';
    position: absolute;
    height: 100px;
    background: linear-gradient(180deg, rgba(0, 0, 0, 0.0001) 0%, #000000 100%);
    bottom: 0;
    left: 0;
    right: 0;
    opacity: 0.8;
    mix-blend-mode: normal;
  }
`;

const StyledImg = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: top;
`;

const StyledLink = styled(Link)`
  border-bottom: 2px solid white;
  position: absolute;
  bottom: 0;
  right: 0;
  margin: 24px;
  color: white;
  text-transform: uppercase;
  font-size: 13px;
  font-weight: bold;
`;

export const CardImage = (props: CardImageProps) => (
  <ImageContainer {...props}>
    <StyledImg src={props.thumbnail} alt="Thumbnail" />
    {props.fullImage && (
      <StyledLink href={props.fullImage} noUnderline={true}>
        Full image
      </StyledLink>
    )}
  </ImageContainer>
);

export default CardImage;
