import { NodeWithChildren, StyleableComponent } from '../../../types';
import React from 'react';
import styled from 'styles/styled-components';

export interface CardTitleProps extends NodeWithChildren, StyleableComponent {
  primary?: boolean;
  secondaryText?: string;
}

const Wrapper = styled.div`
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: ${({ secondaryText }: CardTitleProps) =>
    secondaryText ? 'space-between' : 'flex-start'};
  border-bottom: 1px solid ${({ theme }) => theme.border.light};
  padding: 20px 30px;
  text-transform: uppercase;
`;

const Title = styled.span`
  font-weight: bold;
  opacity: 0.6;
`;

const Secondary = styled.span`
  font-size: ${({ theme }) => theme.fontSize.sm};
  opacity: 0.8;
`;

const PrimaryWrapper = styled.div`
  line-height: 120%;
  font-size: ${({ theme }) => theme.fontSize.lg};
  margin-bottom: 1rem;
`;

export const CardTitle = (props: CardTitleProps) =>
  props.primary ? (
    <PrimaryWrapper className={props.className}>
      {props.children}
    </PrimaryWrapper>
  ) : (
    <Wrapper className={props.className} secondaryText={props.secondaryText}>
      <Title>{props.children}</Title>
      {props.secondaryText && <Secondary>{props.secondaryText}</Secondary>}
    </Wrapper>
  );

export default CardTitle;
