import React, {
  forwardRef,
  useState,
  useCallback,
  useEffect,
  ReactNode,
} from 'react';
import { useHistory } from 'react-router-dom';
import { theme } from 'styles/styled-components';
import MaterialTable, {
  Icons,
  MaterialTableProps,
  MTableToolbar,
  Column,
} from 'material-table';
import * as Container from './Container';
import {
  AddBox,
  ArrowDownward,
  Check,
  ChevronLeft,
  ChevronRight,
  Clear,
  DeleteOutline,
  Edit,
  FilterList,
  FirstPage,
  LastPage,
  Remove,
  SaveAlt,
  ViewColumn,
} from '@material-ui/icons';
import TimeAgo from 'react-timeago';

import CellWithImage from './CellWithImage';
import LargeAvatar from './LargeAvatar';
import EmptyContent from './EmptyContent';
import { PaperProps } from '@material-ui/core';
import StyledToolbar from './StyledToolbar';
import { CSSProperties } from '@material-ui/core/styles/withStyles';
import { ReactComponent as SearchIcon } from './assets/Search.svg';
import { StyleableComponent } from '../../types';
import CustomToolbarMiddle from './CustomToolbarMiddle';

const defaultTableStyle: CSSProperties = {
  display: 'grid',
  gridTemplateRows: '1fr auto',
  fontSize: '1rem',
  overflow: 'hidden',
};

const searchFieldStyle: CSSProperties = {
  border: '1px solid #CCCCCC',
  padding: '6px',
};

const selectedRowStyle: CSSProperties = {
  backgroundColor: theme.background.selectedRow,
  boxShadow: `0 0 0 1px ${theme.border.selectedRow} inset`,
  fontWeight: 500,
};

const normalRowStyle: CSSProperties = {
  boxShadow: `0 -1px 0 0 ${theme.border.default} inset`,
};

const successRowStyle: CSSProperties = {
  ...normalRowStyle,
  backgroundColor: theme.background.success,
};

const dangerRowStyle: CSSProperties = {
  ...normalRowStyle,
  backgroundColor: theme.background.danger,
};

export const PAGE_SIZE = 5;

export type ColumnType =
  | 'string'
  | 'boolean'
  | 'numeric'
  | 'date'
  | 'datetime'
  | 'time'
  | 'currency';

export type SortDirection = 'asc' | 'desc';

export type AlignType = 'center' | 'left' | 'right';

export type RenderColumnType = 'timeago' | 'withImage';

export type RenderType = (data: any) => any;

export interface WithImageFields {
  image: string;
  text: string;
}

export interface ColumnDef {
  align?: AlignType;
  title?: string;
  field: string;
  type?: ColumnType;
  hidden?: boolean;
  searchable?: boolean;
  sorting?: boolean;
  defaultSort?: SortDirection;
  render?: RenderType;
  renderType?: RenderColumnType;
  renderWithImageFields?: WithImageFields;
  cellStyle?: Column<any>['cellStyle'];
  width?: string;
}

export type TableKey = string | number | null | undefined;

export interface ContentWithHeight {
  height: string;
  content: ReactNode;
}

export type EmptyContent = ContentWithHeight | ReactNode;

export interface CustomToolbar {
  middle?: ReactNode;
  right?: ReactNode;
}

export type TableLayout = 'auto' | 'fixed';

export type RowStyleType = 'default' | 'success' | 'danger';

export type RowStyleFunction = (rowData: any) => RowStyleType;

export interface DataTableProps extends StyleableComponent {
  MTOptions?: Partial<MaterialTableProps<any>>;
  keyColumn?: string;
  isLoading?: boolean;
  columns: ColumnDef[];
  selection?: boolean;
  rowClick?: MaterialTableProps<any>['onRowClick'];
  rowClickLink?: (rowData?: any) => string;
  rowStyle?: RowStyleFunction;
  data: MaterialTableProps<any>['data'];
  page?: number;
  totalCount?: number;
  pageSize?: number;
  onPageChange?: (page: number, pageSize: number) => void;
  onPageSizeChange?: (pageSize: number) => void;
  onSearchChange?: (searchText: string) => void;
  sorting?: boolean;
  sortingField?: string;
  defaultSort?: SortDirection;
  selectedRow?: TableKey;
  emptyContent?: EmptyContent;
  stretch?: boolean;
  showSearch?: boolean;
  title?: string;
  customToolbar?: CustomToolbar;
  tableLayout?: TableLayout;
}

export const DataTable = React.memo((props: DataTableProps) => {
  const tableIcons: Icons = {
    Add: forwardRef((props, ref) => <AddBox {...props} ref={ref} />),
    Check: forwardRef((props, ref) => <Check {...props} ref={ref} />),
    Clear: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
    Delete: forwardRef((props, ref) => <DeleteOutline {...props} ref={ref} />),
    DetailPanel: forwardRef((props, ref) => (
      <ChevronRight {...props} ref={ref} />
    )),
    Edit: forwardRef((props, ref) => <Edit {...props} ref={ref} />),
    Export: forwardRef((props, ref) => <SaveAlt {...props} ref={ref} />),
    Filter: forwardRef((props, ref) => <FilterList {...props} ref={ref} />),
    FirstPage: forwardRef((props, ref) => <FirstPage {...props} ref={ref} />),
    LastPage: forwardRef((props, ref) => <LastPage {...props} ref={ref} />),
    NextPage: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
    PreviousPage: forwardRef((props, ref) => (
      <ChevronLeft {...props} ref={ref} />
    )),
    ResetSearch: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
    Search: forwardRef((props, _ref) => <SearchIcon {...props} />),
    SortArrow: forwardRef((props, ref) => (
      <ArrowDownward {...props} ref={ref} />
    )),
    ThirdStateCheck: forwardRef((props, ref) => (
      <Remove {...props} ref={ref} />
    )),
    ViewColumn: forwardRef((props, ref) => <ViewColumn {...props} ref={ref} />),
  };

  const renderColumn = (
    field: string,
    type?: RenderColumnType,
    withImagefields?: WithImageFields,
  ) => {
    switch (type) {
      case 'timeago':
        return (rowData: any) => <TimeAgo date={rowData[field]} />;
      case 'withImage':
        if (withImagefields) {
          return (rowData: any) => (
            <CellWithImage>
              <LargeAvatar
                src={rowData[withImagefields.image]}
                variant="rounded"
              />
              <div className="text" title={rowData[withImagefields.text]}>
                {rowData[withImagefields.text]}
              </div>
            </CellWithImage>
          );
        }
        break;
    }
    return undefined;
  };

  const columns: Column<any>[] = props.columns.map(col => {
    const sorting = props.sortingField === col.field || col.sorting;
    const defaultSort =
      (props.sortingField === col.field && props.defaultSort) ||
      col.defaultSort;
    const width =
      col.width || (props.tableLayout === 'fixed' ? 'auto' : undefined);
    return {
      align: col.align,
      title: col.title,
      field: col.field,
      hidden: col.hidden,
      width: width,
      cellStyle: { ...col.cellStyle, border: 'none' },
      render:
        col.render ||
        renderColumn(col.field, col.renderType, col.renderWithImageFields),
      seachable: col.searchable,
      sorting: sorting,
      defaultSort: defaultSort,
    };
  });
  const sorting =
    props.sorting !== undefined
      ? props.sorting
      : columns.some(c => c.sorting) || undefined;

  const history = useHistory();

  const [selectedRow, setSelectedRow] = useState<TableKey>(props.selectedRow);

  useEffect(() => {
    setSelectedRow(props.selectedRow);
  }, [props.selectedRow]);

  const getRowKey = useCallback(
    (rowData: any) =>
      props.keyColumn ? rowData[props.keyColumn] : rowData.tableData.id,
    [props.keyColumn],
  );

  const onRowClick =
    (props.rowClickLink &&
      ((_e: any, rowData: any) => {
        const path = props.rowClickLink && props.rowClickLink(rowData);
        if (path) {
          history.push(path);
        }
      })) ||
    ((e: any, rowData: any) => {
      setSelectedRow(getRowKey(rowData));
      if (props.rowClick) {
        props.rowClick(e, rowData);
      }
    });

  const getRowStyle = (rowData: any): React.CSSProperties => {
    let style = normalRowStyle;
    if (props.selection && selectedRow === getRowKey(rowData)) {
      style = selectedRowStyle;
    } else if (props.rowStyle) {
      const result = props.rowStyle(rowData);
      if (result === 'success') {
        style = successRowStyle;
      } else if (result === 'danger') {
        style = dangerRowStyle;
      }
    }
    return style;
  };

  const rowStyle = (rowData: any): React.CSSProperties => getRowStyle(rowData);

  let MTOpts = props.MTOptions;
  // Empty content
  if (props.emptyContent) {
    const emptyContent = (
      <EmptyContent height={props.emptyContent['height']}>
        {props.emptyContent['content'] || props.emptyContent}
      </EmptyContent>
    );
    MTOpts = {
      ...MTOpts,
      localization: {
        ...MTOpts?.localization,
        body: {
          ...MTOpts?.localization?.body,
          emptyDataSourceMessage: emptyContent,
        },
      },
    };
  }

  const container = useCallback(
    (containerProps: PaperProps) =>
      props.stretch ? (
        <Container.Stretch {...containerProps} />
      ) : (
        <Container.Default {...containerProps} />
      ),
    [props.stretch],
  );

  const toolbar = useCallback(
    toolbarProps =>
      props.customToolbar ? (
        <StyledToolbar>
          <CustomToolbarMiddle>
            <div className="title">{props.title}</div>
            <div>{props.customToolbar.middle}</div>
          </CustomToolbarMiddle>
          <MTableToolbar {...toolbarProps} />
          {props.customToolbar.right}
        </StyledToolbar>
      ) : (
        <MTableToolbar {...toolbarProps} />
      ),
    [props.customToolbar, props.title],
  );

  const MTProps: MaterialTableProps<any> = {
    icons: tableIcons,
    title: (!props.customToolbar && props.title) || '',
    columns: columns,
    data: props.data,
    page: props.page,
    totalCount: props.totalCount,
    isLoading: props.isLoading,
    onRowClick: onRowClick,
    onChangePage: props.onPageChange,
    onChangeRowsPerPage: props.onPageSizeChange,
    onSearchChange: props.onSearchChange,
    ...MTOpts,
    options: {
      thirdSortClick: false,
      toolbar: !!(props.title || props.customToolbar || props.showSearch),
      draggable: false,
      emptyRowsWhenPaging: false,
      sorting: sorting,
      search: !!props.showSearch,
      searchAutoFocus: true,
      pageSize: props.pageSize || PAGE_SIZE,
      pageSizeOptions: [props.pageSize || PAGE_SIZE],
      rowStyle: rowStyle,
      tableLayout: props.tableLayout || 'auto',
      searchFieldVariant: MTOpts?.options?.searchFieldVariant || 'outlined',
      searchFieldStyle: {
        ...searchFieldStyle,
        ...MTOpts?.options?.searchFieldStyle,
      },
      ...MTOpts?.options,
    },
    components: {
      Container: container,
      Toolbar: toolbar,
      ...MTOpts?.components,
    },
    style: {
      ...defaultTableStyle,
      height: props.stretch ? '100%' : 'initial',
      ...MTOpts?.style,
    },
  };

  return <MaterialTable {...MTProps} />;
});

export default DataTable;
