/**
 *
 * Asynchronously loads the component for Identification
 *
 */

import { lazyLoad } from 'utils/loadable';

export const Identification = lazyLoad(
  () => import('./index'),
  module => module.Identification,
);
