import React from 'react';
import styled, { css, IThemeInterface } from 'styles/styled-components';
import { ThemedStyledProps } from 'styled-components';
import { NodeWithChildren, StyleableComponent } from '../../../types';

export type CssJustify =
  | 'center'
  | 'space-between'
  | 'space-around'
  | 'flex-start'
  | 'flex-end';

export type CssAlign = 'center' | 'flex-start' | 'flex-end' | 'baseline';

export type CssGridAutoColumns = '1fr' | 'auto';

export interface CardRowProps extends NodeWithChildren, StyleableComponent {
  col?: boolean;
  p?: string;
  pt?: string;
  pl?: string;
  pb?: string;
  pr?: string;
  justify?: CssJustify;
  alignItems?: CssAlign;
  gridAutoColumns?: CssGridAutoColumns;
  border?: boolean;
  height?: string;
}

type ThemeAndProps = ThemedStyledProps<CardRowProps, IThemeInterface>;

const paddingTop = css`
  padding-top: ${({ p, pt, theme }: ThemeAndProps) =>
    pt ? pt : p ? p : theme.padding};
`;

const paddingRight = css`
  padding-right: ${({ p, pr, theme }: ThemeAndProps) =>
    pr ? pr : p ? p : theme.padding};
`;

const paddingBottom = css`
  padding-bottom: ${({ p, pb, theme }: ThemeAndProps) =>
    pb ? pb : p ? p : theme.padding};
`;

const paddingLeft = css`
  padding-left: ${({ p, pl, theme }: ThemeAndProps) =>
    pl ? pl : p ? p : theme.padding};
`;

const padding = css`
  ${paddingTop}
  ${paddingRight}
  ${paddingBottom}
  ${paddingLeft}
`;

const rowStyles = css`
  grid-auto-flow: column;
  grid-gap: 1.5rem;
  grid-auto-columns: 1fr;
`;

const columnStyles = css`
  grid-auto-flow: row;
  grid-gap: 0;
  grid-template-columns: 100%;
`;

const StyledRow = styled.div`
  display: grid;
  width: 100%;
  box-sizing: border-box;
  overflow: hidden;
  ${padding}
  height: ${({ height }: CardRowProps) => height || 'initial'};
  justify-content: ${({ justify }: CardRowProps) => justify || 'space-between'};
  align-items: ${({ alignItems }: CardRowProps) => alignItems || 'center'};
  ${({ col }: CardRowProps) => (col ? columnStyles : rowStyles)}
  ${({ gridAutoColumns }: CardRowProps) =>
    gridAutoColumns &&
    css`
      grid-auto-columns: ${gridAutoColumns};
    `}

  &:not(:last-child) {
    border-bottom: ${({ border }: CardRowProps) =>
      border === false ? '0' : '1px'}
      solid ${({ theme }) => theme.border.default};
  }
`;

export const CardRow = (props: CardRowProps) => <StyledRow {...props} />;

export default CardRow;
