import styled from 'styles/styled-components';

const CardTitle = styled.div`
  display: flex;
  align-items: center;
  line-height: 120%;
  font-size: ${({ theme }) => theme.fontSize.lg};
`;

export default CardTitle;
