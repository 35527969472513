/**
 *
 * Asynchronously loads the component for AuthenticityItem
 *
 */

import { lazyLoad } from 'utils/loadable';

export const AuthenticityItem = lazyLoad(
  () => import('./index'),
  module => module.AuthenticityItem,
);
