import styled from 'styles/styled-components';

const Wrapper = styled.header`
  display: grid;
  grid-template-areas: 'logo nav user';
  grid-template-columns: auto 1fr auto;
  grid-template-rows: 1fr;
  column-gap: 32px;
  align-items: center;
  justify-items: center;
  background: ${({ theme }) => theme.background.default};
  border-bottom: 1px solid ${({ theme }) => theme.border.default};
  height: 72px;
  width: 100%;
  padding: 0 32px;
  z-index: 1;

  @media (max-width: 500px) {
    grid-template-areas:
      'logo user'
      'nav nav';
    height: 120px;
    padding: 0 1rem;
    column-gap: normal;
  }
`;

export default Wrapper;
