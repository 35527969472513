import React from 'react';
import styled from 'styles/styled-components';

import { BannerProps } from './index';
import { ReactComponent as InfoIcon } from './assets/check-circle-info.svg';
import { ReactComponent as SuccessIcon } from './assets/check-circle-success.svg';
import { ReactComponent as DangerIcon } from './assets/x-circle-danger.svg';

const Wrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 1.125em;
  height: 1.125em;
  margin-right: 0.55em;
`;

const icon = (props: BannerProps) => {
  let iconElement;
  switch (props.type) {
    case 'info':
      iconElement = <InfoIcon />;
      break;
    case 'success':
      iconElement = <SuccessIcon />;
      break;
    case 'danger':
      iconElement = <DangerIcon />;
      break;
  }

  return <Wrapper>{iconElement}</Wrapper>;
};

export default icon;
