import { StyleableComponent } from '../../types';
import React from 'react';
import styled from 'styles/styled-components';

const Wrapper = styled.div`
  display: grid;
  grid-template-columns: 1fr 23px 1fr;
  grid-template-rows: 40px;
  justify-content: center;
  align-items: center;
  border-radius: 20px;
  border: 2px solid ${({ theme }) => theme.border.default};
  overflow: hidden;
  background: white;
`;

const Separator = styled.div`
  height: 100%;
  width: 23px;
  transform: skew(-30deg) translate(12px);
  background: ${({ theme }) => theme.button.primary.background.default};
`;

const Option = styled.div`
  overflow: visible;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  font-size: ${({ theme }) => theme.fontSize.button};
  font-weight: bold;
  background: white;
  padding: 10px 14px;
  text-transform: uppercase;
  cursor: pointer;

  &.activated {
    color: white;
    background: ${({ theme }) => theme.button.primary.background.default};
  }

  &:first-of-type {
    padding-left: 20px;
  }

  &:last-of-type {
    padding-right: 20px;
  }

  &:first-of-type.activated + div {
    transform: skew(-30deg) translate(-12px);
  }
`;

export interface SwitchOption {
  id: string;
  title: string;
}

export type SwitchOptions = [SwitchOption, SwitchOption];

export interface SwitchProps extends StyleableComponent {
  options: SwitchOptions;
  activatedOption: string;
  onChange: (optionId: string) => void;
}

export const Switch = (props: SwitchProps) => {
  const onChange = (optionId: string) => {
    props.onChange(optionId);
  };

  const firstOptionClickHandler = () => {
    onChange(props.options[0].id);
  };

  const secondOptionClickHandler = () => {
    onChange(props.options[1].id);
  };

  return (
    <Wrapper className={props.className}>
      <Option
        className={
          props.activatedOption === props.options[0].id ? 'activated' : ''
        }
        onClick={firstOptionClickHandler}
      >
        {props.options[0].title}
      </Option>
      <Separator />
      <Option
        className={
          props.activatedOption === props.options[1].id ? 'activated' : ''
        }
        onClick={secondOptionClickHandler}
      >
        {props.options[1].title}
      </Option>
    </Wrapper>
  );
};

export default Switch;
