import { StyleableComponent } from '../../types';
import React, { useState } from 'react';
import RIL from 'react-image-lightbox';
import 'react-image-lightbox/style.css';

export interface LightboxProps extends StyleableComponent {
  images: string[];
  title?: string;
  caption?: string;
  open: boolean;
  onClose: any;
}

export const Lightbox = ({
  images,
  title,
  caption,
  open,
  onClose,
  className,
}: LightboxProps) => {
  const [photoIndex, setPhotoIndex] = useState(0);
  const count = images.length;

  return open ? (
    <RIL
      mainSrc={images[photoIndex]}
      nextSrc={images[(photoIndex + 1) % count]}
      prevSrc={images[(photoIndex + count - 1) % count]}
      imageTitle={title}
      imageCaption={caption}
      onCloseRequest={() => {
        onClose();
      }}
      onMovePrevRequest={() => setPhotoIndex((photoIndex + count - 1) % count)}
      onMoveNextRequest={() => setPhotoIndex((photoIndex + 1) % count)}
      wrapperClassName={className}
    />
  ) : (
    <React.Fragment />
  );
};

export default Lightbox;
