import * as styledComponents from 'styled-components';

export interface ColorPalette {
  default: string;
  primary: string;
  secondary: string;
  light: string;
  dark: string;
  disabled: string;
  neutral: string;
  special: string;
  info: string;
  success: string;
  danger: string;
  selectedRow: string;
  avatar: string;
}

export interface ColorStates {
  default: string;
  hover: string;
  disabled: string;
  readonly?: string;
}

export interface ButtonPalette {
  color: ColorStates;
  background: ColorStates;
  border: ColorStates;
}

// theme.ts
// your theme variables
export interface IThemeInterface {
  color: ColorPalette;
  background: ColorPalette;
  border: ColorPalette;
  button: {
    primary: ButtonPalette;
    secondary: ButtonPalette;
    success: ButtonPalette;
    danger: ButtonPalette;
    size: {
      small: string;
      default: string;
      large: string;
    };
  };
  link: {
    default: ColorStates;
    primary: ColorStates;
    secondary: ColorStates;
    danger: ColorStates;
  };
  fontSize: {
    h1: string;
    h2: string;
    pageTitle: string;
    button: string;
    lg: string;
    md: string;
    default: string;
    sm: string;
    xs: string;
  };
  padding: string;
  borderRadius: string;
}

export const theme: IThemeInterface = {
  color: {
    default: '#1A1A1A',
    primary: '#FFF',
    secondary: '#00A4B2',
    light: '#4D4D4D',
    dark: '#1A1A1A',
    disabled: '#808080',
    neutral: '#666',
    special: '#00454D',
    info: '#003a63',
    success: '#004D1E',
    danger: '#800009',
    selectedRow: '#1A1A1A',
    avatar: '#FFF',
  },
  background: {
    default: '#FFF',
    primary: '#FFF',
    secondary: '#F2F2F2',
    light: '#FFF',
    dark: '#1A1A1A',
    disabled: '#CCC',
    neutral: '#F2F2F2',
    special: '#D9FCFF',
    info: '#CFEBFF',
    success: '#DAFFE8',
    danger: '#FFE6E7',
    selectedRow: '#F2FEFF',
    avatar: '#00B2FF',
  },
  border: {
    default: '#D9D9D9',
    primary: '#D9D9D9',
    secondary: '#00A4B2',
    light: '#EDF0F0',
    dark: '#1A1A1A',
    disabled: '#CCC',
    neutral: '#F2F2F2',
    special: '#D9FCFF',
    info: '#2D7EB9',
    success: '#2DB99B',
    danger: '#B92D2D',
    selectedRow: '#00C7D9',
    avatar: '#00B2FF',
  },
  button: {
    primary: {
      color: {
        default: '#FFF',
        hover: '#FFF',
        disabled: '#FFF',
      },
      background: {
        default: '#1A1A1A',
        hover: '#4D4D4D',
        disabled: '#CCCCCC',
      },
      border: {
        default: '#1A1A1A',
        hover: '#4D4D4D',
        disabled: '#CCCCCC',
      },
    },
    secondary: {
      color: {
        default: '#1A1A1A',
        hover: '#1A1A1A',
        disabled: '#CCC',
      },
      background: {
        default: 'linear-gradient(180deg, #FFF 0%, #FFF 51.04%, #F2F2F2 100%)',
        hover: '#FFF',
        disabled: '#FFF',
      },
      border: {
        default: '#B3B3B3',
        hover: '#B3B3B3',
        disabled: '#CCC',
      },
    },
    success: {
      color: {
        default: '#FFF',
        hover: '#FFF',
        disabled: '#FFF',
        readonly: '#008032',
      },
      background: {
        default: '#008032',
        hover: '#00D955',
        disabled: '#CCC',
        readonly: '#F2F2F2',
      },
      border: {
        default: '#008032',
        hover: '#00D955',
        disabled: '#CCC',
        readonly: '#F2F2F2',
      },
    },
    danger: {
      color: {
        default: '#FFF',
        hover: '#FFF',
        disabled: '#FFF',
      },
      background: {
        default: '#800009',
        hover: '#CC1F2B',
        disabled: '#CCC',
      },
      border: {
        default: '#800009',
        hover: '#CC1F2B',
        disabled: '#CCC',
      },
    },
    size: {
      small: '40px',
      default: '48px',
      large: '56px',
    },
  },
  link: {
    default: {
      default: '#00A4B2',
      hover: '#00A4B2',
      disabled: '#CCC',
    },
    primary: {
      default: '#1A1A1A',
      hover: '#1A1A1A',
      disabled: '#CCC',
    },
    secondary: {
      default: '#4D4D4D',
      hover: '#4D4D4D',
      disabled: '#CCC',
    },
    danger: {
      default: '#CC1F2B',
      hover: '#CC1F2B',
      disabled: '#CCC',
    },
  },
  fontSize: {
    lg: '24px',
    md: '18px',
    default: '1rem',
    sm: '14px',
    xs: '13px',
    h1: '32px',
    h2: '24px',
    pageTitle: '34px',
    button: '14px',
  },
  padding: '24px',
  borderRadius: '4px',
};

const {
  default: styled,
  css,
  ThemeProvider,
} = (styledComponents as unknown) as styledComponents.ThemedStyledComponentsModule<
  IThemeInterface
>;

export { css, ThemeProvider };
export default styled;
