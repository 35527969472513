import styled from 'styles/styled-components';

export interface EmptyContentProps {
  height?: string;
}

const EmptyContent = styled.div`
  display: grid;
  width: 100%;
  height: ${({ height }: EmptyContentProps) => height || '100%'};
  grid-auto-flow: row;
  grid-gap: 16px;
  align-content: center;
  justify-content: center;
`;

export default EmptyContent;
