import React, { ReactNode } from 'react';
import {
  ThemeProvider as StyledThemeProvider,
  theme as styledTheme,
  IThemeInterface,
} from 'styles/styled-components';
import { ThemeProvider as MuiThemeProvider, Theme } from '@material-ui/core';
import muiTheme from 'styles/muiTheme';

export interface DefaultThemeProviderProps {
  children: ReactNode;
  muiTheme?: Theme;
  styledTheme?: IThemeInterface;
}

export const DefaultThemeProvider = (props: DefaultThemeProviderProps) => (
  <StyledThemeProvider theme={props.styledTheme || styledTheme}>
    <MuiThemeProvider theme={props.muiTheme || muiTheme}>
      {props.children}
    </MuiThemeProvider>
  </StyledThemeProvider>
);

export default DefaultThemeProvider;
