import styled, { IThemeInterface } from 'styles/styled-components';
import { ThemedStyledProps } from 'styled-components';
import { CircularProgress } from '@material-ui/core';

export interface LoadingProps {
  secondary?: boolean;
}

export const Loading = styled(CircularProgress)`
  position: absolute;
  color: ${({
    secondary,
    theme,
  }: ThemedStyledProps<LoadingProps, IThemeInterface>) =>
    secondary
      ? theme.button.secondary.color.default
      : theme.button.primary.color.default} !important;
`;

export default Loading;
