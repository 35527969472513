import { StyleableComponent } from '../../types';
import React from 'react';
import styled from 'styles/styled-components';
import { NavLink, NavLinkProps } from '../NavLink';

const StyledNav = styled.nav`
  grid-area: nav;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  padding: 0;
  width: 100%;
  height: 100%;
`;

export interface NavProps extends StyleableComponent {
  navLinks: NavLinkProps[];
  disabled?: boolean;
}

export const Nav = (props: NavProps) => {
  const navLinks = props.navLinks.map((navLinkProps, i) => (
    <NavLink
      key={i}
      {...navLinkProps}
      disabled={props.disabled || navLinkProps.disabled}
    />
  ));

  return <StyledNav className={props.className}>{navLinks}</StyledNav>;
};

export default Nav;
