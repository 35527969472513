import styled from 'styles/styled-components';
import { Link } from 'react-router-dom';

import style from './linkStyle';

export const StyledLink = styled(Link)`
  ${style}
`;

export const StyledA = styled.a`
  ${style}
`;

export const StyledBackLink = styled(StyledA)`
  font-weight: 500;
`;
