import styled from 'styles/styled-components';

export const CardList = styled.ul`
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 20px 30px;
  margin: 0;
  list-style: none;
`;

export default CardList;
