import { css, IThemeInterface } from 'styles/styled-components';
import { ThemedStyledProps } from 'styled-components';

import { LinkProps } from './index';

type ThemeAndProps = ThemedStyledProps<LinkProps, IThemeInterface>;

const linkStyle = css`
  display: inline-grid;
  grid-auto-flow: column;
  grid-gap: 8px;
  align-items: center;
  justify-content: flex-start;
  background: transparent;
  padding: 0;
  font-size: ${({ theme }) => theme.fontSize.default};
  line-height: normal;
  text-decoration: ${({ noUnderline }: LinkProps) =>
    noUnderline ? 'none' : 'underline'};
  cursor: pointer;
  transition: 0.6s;
  ${({ theme, color, disabled }: ThemeAndProps) => {
    // Colors
    let colors = theme.link.default;
    switch (color) {
      default:
      case 'default':
        break;
      case 'primary':
        colors = theme.link.primary;
        break;
      case 'secondary':
        colors = theme.link.secondary;
        break;
      case 'danger':
        colors = theme.link.danger;
        break;
    }

    // Disabled style
    if (disabled) {
      return css`
        color: ${colors.disabled};
        pointer-events: none;
      `;
    }
    // Styles
    return css`
      color: ${colors.default};
      &:hover {
        color: ${colors.hover};
      }
    `;
  }}

  &:hover {
    text-decoration: none;
  }

  &:focus {
    outline: none;
  }
`;

export default linkStyle;
