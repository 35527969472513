import { StyleableComponent } from '../../types';
import React, { ImgHTMLAttributes } from 'react';
import styled, { css } from 'styles/styled-components';

export interface ImgContainerProps
  extends ImgHTMLAttributes<{}>,
    StyleableComponent {
  width?: string;
  height?: string;
  minWidth?: string;
  minHeight?: string;
  maxWidth?: string;
  maxHeight?: string;
  objectFit?: string;
  onClick?: any;
}

const Wrapper = styled.div`
  width: ${({ width }: ImgContainerProps) => width || '100%'};
  height: ${({ height }: ImgContainerProps) => height || '100%'};
  min-width: ${({ minWidth }: ImgContainerProps) => minWidth || 'initial'};
  min-height: ${({ minHeight }: ImgContainerProps) => minHeight || 'initial'};
  max-width: ${({ maxWidth }: ImgContainerProps) => maxWidth || 'initial'};
  max-height: ${({ maxHeight }: ImgContainerProps) => maxHeight || 'initial'};
  overflow: hidden;

  img {
    ${({ objectFit }: ImgContainerProps) =>
      objectFit &&
      css`
        object-fit: ${objectFit} !important;
      `}
  }
  ${({ onClick }: ImgContainerProps) =>
    onClick &&
    css`
      cursor: pointer;
    `}
`;

const Img = styled.img`
  width: 100%;
  height: 100%;
  object-fit: contain;
`;

export const ImgContainer = (props: ImgContainerProps) => {
  const imgProps: ImgHTMLAttributes<{}> = { ...props };
  return (
    <Wrapper {...props}>
      <Img {...imgProps} />
    </Wrapper>
  );
};

export default ImgContainer;
