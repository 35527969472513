import Regular from './PxGroteskRegular.ttf';
import Light from './PxGroteskLight.ttf';
import Bold from './PxGroteskBold.ttf';
import RegularItalic from './PxGroteskRegularItalic.ttf';
import LightItalic from './PxGroteskLightItalic.ttf';
import BoldItalic from './PxGroteskBoldItalic.ttf';
import Screen from './PxGroteskScreen.ttf';

export default {
  Regular,
  Light,
  Bold,
  RegularItalic,
  LightItalic,
  BoldItalic,
  Screen,
};
