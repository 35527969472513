import styled from 'styles/styled-components';

export interface MainContainerProps {
  direction?: 'row' | 'column';
}

export const MainContainer = styled.div`
  width: 100vw;
  height: 100vh;
  overflow: hidden;
  display: flex;
  flex-direction: ${({ direction }: MainContainerProps) =>
    direction || 'column'};
  background: ${({ theme }) => theme.background.default};
`;

export default MainContainer;
