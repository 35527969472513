import styled, { css } from 'styles/styled-components';

import { ButtonProps } from '.';

const LabelWrapper = styled.div`
  ${({ loading }: ButtonProps) =>
    loading
      ? css`
          position: relative;
          display: flex;
          justify-content: center;
          align-items: center;

          .label {
            visibility: hidden;
          }
        `
      : css`
          .label {
            display: grid;
            grid-auto-flow: column;
            grid-auto-columns: auto;
            grid-gap: 8px;
            align-items: center;
          }
        `}
`;

export default LabelWrapper;
