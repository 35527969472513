import { css } from 'styled-components';

import fonts from './fonts/';

const { NBAkademie } = fonts;

const fontFace = css`
  /* Font: NB Akademie */
  @font-face {
    font-family: 'NB Akademie';
    src: url(${NBAkademie.Light}) format('truetype');
    font-weight: 300;
    font-style: normal;
  }
  @font-face {
    font-family: 'NB Akademie';
    src: url(${NBAkademie.Regular}) format('truetype');
    font-weight: 400;
    font-style: normal;
  }
  @font-face {
    font-family: 'NB Akademie';
    src: url(${NBAkademie.Medium}) format('truetype');
    font-weight: 500;
    font-style: normal;
  }
  @font-face {
    font-family: 'NB Akademie';
    src: url(${NBAkademie.Bold}) format('truetype');
    font-weight: 700;
    font-style: normal;
  }
  @font-face {
    font-family: 'NB Akademie';
    src: url(${NBAkademie.Black}) format('truetype');
    font-weight: 900;
    font-style: normal;
  }
  @font-face {
    font-family: 'NB Akademie';
    src: url(${NBAkademie.LightItalic}) format('truetype');
    font-weight: 300;
    font-style: italic;
  }
  @font-face {
    font-family: 'NB Akademie';
    src: url(${NBAkademie.RegularItalic}) format('truetype');
    font-weight: 400;
    font-style: italic;
  }
  @font-face {
    font-family: 'NB Akademie';
    src: url(${NBAkademie.MediumItalic}) format('truetype');
    font-weight: 500;
    font-style: italic;
  }
  @font-face {
    font-family: 'NB Akademie';
    src: url(${NBAkademie.BoldItalic}) format('truetype');
    font-weight: 700;
    font-style: italic;
  }
  @font-face {
    font-family: 'NB Akademie';
    src: url(${NBAkademie.BlackItalic}) format('truetype');
    font-weight: 900;
    font-style: italic;
  }
  /* Font: Baskerville */
  @font-face {
    font-family: 'Baskerville';
    src: url(${fonts.Baskerville}) format('truetype');
    font-weight: normal;
    font-style: normal;
  }
`;

export default fontFace;
