import { NodeWithChildren, StyleableComponent } from '../../types';
import React, { ReactNode } from 'react';
import { LinkProps as DOMLinkProps } from 'react-router-dom';
import { StyledLink, StyledA } from './StyledLink';

export type LinkColor = 'default' | 'primary' | 'secondary' | 'danger';

export interface LinkProps
  extends Partial<DOMLinkProps>,
    NodeWithChildren,
    StyleableComponent {
  href?: string;
  noUnderline?: boolean;
  color?: LinkColor;
  disabled?: boolean;
  icon?: ReactNode;
}

export const Link = (props: LinkProps) => {
  let link: JSX.Element;
  if (props.to) {
    link = (
      <StyledLink to={props.to} {...props}>
        {props.icon}
        {props.children}
      </StyledLink>
    );
  } else {
    link = (
      <StyledA {...props} target="_blank">
        {props.icon}
        {props.children}
      </StyledA>
    );
  }
  return link;
};

export default Link;
