import styled, { css } from 'styles/styled-components';

import { BannerProps } from './index';

const infoStyle = css`
  background: ${({ theme }) => theme.background.info};
  border-color: ${({ theme }) => theme.border.info};
`;

const successStyle = css`
  background: ${({ theme }) => theme.background.success};
  border-color: ${({ theme }) => theme.border.success};
`;

const dangerStyle = css`
  background: ${({ theme }) => theme.background.danger};
  border-color: ${({ theme }) => theme.border.danger};
`;

const wrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: ${({ primary, height }: BannerProps) =>
    height || (primary ? '120px' : '80px')};
  width: ${({ fullWidth }: BannerProps) => (fullWidth ? '100%' : 'auto')};
  border: 1px solid black;
  background: white;
  box-sizing: border-box;
  border-radius: 4px;
  padding: 0.75em 1.6em;
  font-size: ${({ primary }: BannerProps) => (primary ? '1.5rem' : '1rem')};

  ${({ type }: BannerProps) => {
    switch (type) {
      case 'info':
        return infoStyle;
      case 'success':
        return successStyle;
      case 'danger':
        return dangerStyle;
    }
  }};
`;

export default wrapper;
