import { NodeWithChildren, StyleableComponent } from '../../types';
import React from 'react';
import {
  NavLink as ReactNavLink,
  NavLinkProps as ReactNavLinkProps,
} from 'react-router-dom';
import styled, { css } from 'styles/styled-components';

export interface NavLinkProps
  extends ReactNavLinkProps,
    NodeWithChildren,
    StyleableComponent {
  // notification?: number | null;
  disabled?: boolean;
}

const activeStyle = css`
  color: ${({ theme }) => theme.color.default};
  border-bottom: 2px solid ${({ theme }) => theme.color.default};
  margin-bottom: -1px;
`;

const hoverStyle = css`
  &:hover {
    background: ${({ theme }) => theme.background.secondary};
  }
`;

const disabledStyle = css`
  pointer-events: none;
  opacity: 0.5;
`;

const StyledNavLink = styled(ReactNavLink)`
  color: ${({ theme }) => theme.color.light};
  text-decoration: none;
  padding: 26px 16px 25px;
  height: 100%;
  line-height: normal;

  &.active {
    ${activeStyle}
  }

  ${({ disabled }: NavLinkProps) => (disabled ? disabledStyle : hoverStyle)}
`;

export const NavLink = (props: NavLinkProps) => <StyledNavLink {...props} />;

export default NavLink;
