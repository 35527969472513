import React from 'react';
import styled, { css, ThemedStyledProps } from 'styled-components';
import { Container } from '@material-ui/core';
import { IThemeInterface } from 'styles/styled-components';
import { NodeWithChildren } from '../../types';

export interface ContentContainerProps extends NodeWithChildren {
  background?: 'primary' | 'secondary';
  minWidth?: string;
  fluid?: boolean;
}

type ThemeAndProps = ThemedStyledProps<ContentContainerProps, IThemeInterface>;

const Wrapper = styled.div`
  display: flex;
  width: 100%;
  height: 100%;
  background: ${({ background, theme }: ThemeAndProps) =>
    background === 'secondary'
      ? theme.background.secondary
      : theme.background.default};
  flex: 1;
  overflow: auto;
  ${({ minWidth }: ContentContainerProps) =>
    css`
      min-width: ${minWidth};
    `}

  @media (max-width: 500px) {
    min-width: initial;
  }
`;

const StyledContainer = styled(Container)`
  display: flex !important;
  flex-direction: column;
  flex: 1;
  overflow: visible;
`;

export const ContentContainer = (props: ContentContainerProps) => {
  const options: ContentContainerProps = {
    background: props.background,
    minWidth: props.minWidth,
  };

  return (
    <Wrapper {...props}>
      <StyledContainer {...options} maxWidth={props.fluid ? false : undefined}>
        {props.children || {}}
      </StyledContainer>
    </Wrapper>
  );
};

export default ContentContainer;
