import styled from 'styled-components';

const styledForm = styled.form`
  display: flex;
  flex-direction: column;

  &.inline {
    flex-direction: row;
    width: 100%;
  }
`;

export default styledForm;
