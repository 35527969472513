import React from 'react';
import {
  Card as MuiCard,
  CardProps as MuiCardProps,
  withStyles,
} from '@material-ui/core';
import styled, { theme } from 'styles/styled-components';

export interface CardProps extends MuiCardProps {
  height?: string;
}

const CardWithStyles = withStyles({
  root: {
    display: 'flex',
    flexDirection: 'column',
    borderRadius: theme.borderRadius,
  },
})(MuiCard);

const StyledCard = styled(CardWithStyles)`
  height: ${({ height }: CardProps) => height || 'initial'};
`;

// @ts-ignore
export const Card = (props: CardProps) => <StyledCard {...props} />;

export default Card;
