import React from 'react';
import styled from 'styled-components';

const Wrapper = styled.span`
  color: #d2d2d2;
`;

const Slash = () => <Wrapper>/</Wrapper>;

export default Slash;
