/**
 *
 * Asynchronously loads the component for Authenticity
 *
 */

import { lazyLoad } from 'utils/loadable';

export const Authenticity = lazyLoad(
  () => import('./index'),
  module => module.Authenticity,
);
