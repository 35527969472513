import { StyleableComponent } from '../../../types';
import React from 'react';
import styled from 'styles/styled-components';
import { Checkbox, CheckboxProps } from '..';

const Wrapper = styled.div`
  display: inline-grid;
  grid-auto-flow: ${({ inline }: { inline?: boolean }) =>
    inline ? 'column' : 'row'};
  background-color: ${({ theme }) => theme.background.default};
  border-radius: ${({ theme }) => theme.borderRadius};
  padding-left: 11px;
`;

export interface CheckboxListProps extends StyleableComponent {
  checkboxes: CheckboxProps[];
  inline?: boolean;
}

export const CheckboxList = (props: CheckboxListProps) => {
  const chkList = props.checkboxes.map((chk, i) => (
    <Checkbox key={i} {...chk} />
  ));
  return (
    <Wrapper inline={props.inline} className={props.className}>
      {chkList}
    </Wrapper>
  );
};

export default CheckboxList;
