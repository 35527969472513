import {
  put,
  select,
  takeLatest,
  delay,
  cancel,
  call,
} from 'redux-saga/effects';
import { Task } from 'redux-saga';
import { actions } from './slice';
import {
  selectIdToken,
  selectRefreshToken,
  selectUsername,
  selectAutoLogout,
  selectIdExp,
  selectUseCamera,
} from './selectors';
import { GlobalState } from './types';
import axios from 'utils/axios';
import logger from 'utils/logger';
import {
  decodeToken,
  getIdToken,
  getProtectionCamera,
  getRefreshToken,
  getVerificationCamera,
  removeIdToken,
  removeProtectionCamera,
  removeRefreshToken,
  removeVerificationCamera,
  setIdToken,
  setRefreshToken,
} from 'utils/storageHelper';

/** Refresh time in ms (55 min) */
const REFRESH_TIME = 55 * 60 * 1000;
/** Logout time after refresh in ms (5 min) */
const LOGOUT_TIME_AFTER_REFRESH = 5 * 60 * 1000;

export function* login() {
  logger('[saga/global/login]');
  // ID Token
  const idToken = yield select(selectIdToken);
  if (idToken) {
    const state: Partial<GlobalState> = {};
    yield setIdToken(idToken);
    // Get user info from decoded ID Token
    const payload = yield decodeToken(idToken);
    state.username = yield payload.email;
    state.userId = yield payload.user_id;
    state.idExp = yield payload.exp;

    // Refresh token
    const refreshToken = yield select(selectRefreshToken);
    if (refreshToken) {
      yield setRefreshToken(refreshToken);
    }

    yield put(actions.loginSuccess(state));
  }
}

export function* loginSuccess() {
  logger('[saga/global/login/success]');
  // Wait for 5 minutes before IdToken expiration to refresh
  const idExp = yield select(selectIdExp);
  let refreshTime = yield REFRESH_TIME;
  if (idExp) {
    refreshTime = yield idExp * 1000 - Date.now() - LOGOUT_TIME_AFTER_REFRESH;
  }
  if (refreshTime > 0) {
    yield delay(refreshTime);
  }
  yield put(actions.refresh());
}

export function* refresh() {
  logger('[saga/global/refresh]');
  const refreshToken = yield select(selectRefreshToken);
  if (refreshToken) {
    const email = yield select(selectUsername);
    try {
      const response = yield call(axios.post, '/refresh', {
        email,
        refreshToken,
      });
      const { idToken } = yield response.data;
      yield put(actions.refreshSuccess(idToken));
    } catch (error) {
      logger('[saga/global/refresh] Error', error.response);
      // Logout user if refresh token expires (unauthorized error)
      if (error.response.statusCode !== 404) {
        yield put(actions.autoLogout(true));
      }
    }
  }
  // Logout user, if refresh token is not found
  else {
    yield put(actions.autoLogout(true));
  }
}

export function* logout(postLoginTask: Task, refreshTask: Task) {
  logger('[saga/global/logout]');
  // Cancel tasks with delay
  yield cancel(postLoginTask);
  yield cancel(refreshTask);
  // Remove tokens from localstorage
  yield removeIdToken();
  yield removeRefreshToken();
  // Remove calibration images
  yield removeProtectionCamera();
  yield removeVerificationCamera();
}

export function* autoLogout() {
  logger('[saga/global/logout/automatic]');
  const autoLogout = yield select(selectAutoLogout);
  if (autoLogout) {
    // Wait for IdToken expiration
    const idExp = yield select(selectIdExp);
    let logoutTime = yield LOGOUT_TIME_AFTER_REFRESH;
    if (idExp) {
      logoutTime = yield idExp * 1000 - Date.now();
    }
    if (logoutTime > 0) {
      yield delay(logoutTime);
    }
    yield put(actions.logout());
  }
}

export function* relogin() {
  logger('[saga/global/relogin]');
  // Check if user is already logged
  const token = yield select(selectIdToken);
  if (!token) {
    // Get IdToken from localstorage
    const localIdToken = yield getIdToken();
    if (localIdToken) {
      const state: Partial<GlobalState> = {};
      state.idToken = localIdToken;

      // Get refresh token from localstorage
      const localRefreshToken = yield getRefreshToken();
      if (localRefreshToken) {
        state.refreshToken = localRefreshToken;
      }

      const useCamera = yield select(selectUseCamera);
      if (useCamera) {
        // Calibration images from sessionstorage
        const protectionCamera = yield getProtectionCamera();
        const verificationCamera = yield getVerificationCamera();
        state.calibrationProtectionPhoto = protectionCamera;
        state.calibrationVerificationPhoto = verificationCamera;
        state.isCalibrated = !!(protectionCamera && verificationCamera);
      }

      // Login
      yield put(actions.login(state));
    } else {
      put(actions.logout());
    }
  }
}

export function* globalSaga() {
  yield takeLatest(actions.login.type, login);
  const postLoginTask = yield takeLatest(
    actions.loginSuccess.type,
    loginSuccess,
  );
  const refreshTask = yield takeLatest(actions.refresh.type, refresh);
  yield takeLatest(actions.refreshSuccess.type, login);
  yield takeLatest(actions.logout.type, logout, postLoginTask, refreshTask);
  yield takeLatest(actions.relogin.type, relogin);
}
