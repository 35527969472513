import { theme } from 'styles/styled-components';
import { ToggleButton as MuiToggleButton } from '@material-ui/lab';
import { withStyles } from '@material-ui/core';

const StyledToggleButton = withStyles({
  root: {
    padding: '9px 16px',
    background: theme.button.secondary.background.default,
    color: theme.button.secondary.color.default,
    borderColor: theme.button.secondary.border.default,
    '&:hover': {
      background: theme.button.secondary.background.hover,
      color: theme.button.secondary.color.hover,
    },
    '&:not(:first-child)': {
      borderLeft: `1px solid ${theme.button.secondary.border.default}`,
    },
  },
  selected: {
    background: `${theme.button.primary.background.default} !important`,
    color: `${theme.button.primary.color.default} !important`,
    borderColor: `${theme.button.primary.border.default} !important`,
  },
  label: {
    textTransform: 'none',
  },
})(MuiToggleButton);

export default StyledToggleButton;
