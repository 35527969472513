import React from 'react';
import Wrapper from './Wrapper';
import { ReactComponent as LogoIcon } from './assets/vp-mark.svg';
import { StyleableComponent } from '../../types';

export interface LogoProps extends StyleableComponent {}

export const Logo = (props: LogoProps) => (
  <Wrapper className={props.className}>
    <LogoIcon />
  </Wrapper>
);

export default Logo;
