import styled from 'styles/styled-components';

import { Select } from '@material-ui/core';

import { inputStyles, fontStyles } from './inputStyles';

export const StyledSelect = styled(Select)`
  ${inputStyles}
  ${fontStyles}
`;
