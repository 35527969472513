import { css, IThemeInterface } from 'styles/styled-components';
import { ThemedStyledProps } from 'styled-components';
import { InputProps } from './index';

type ThemeAndProps = ThemedStyledProps<InputProps, IThemeInterface>;

export const fontStyles = css`
  &,
  input {
    font-size: ${({ theme, primary }: ThemeAndProps) => {
      return primary ? theme.fontSize.lg : theme.fontSize.default;
    }} !important;
  }
`;

export interface InputStyleProps {
  width?: string;
}

export const inputStyles = css`
  min-width: ${({ width }: InputStyleProps) =>
    width !== undefined ? width : '200px'} !important;
  ${({ width }: InputStyleProps) =>
    width &&
    css`
      width: ${width} !important;
    `};
  label {
    color: ${({ theme }) => theme.color.light};
  }
`;

export default inputStyles;
