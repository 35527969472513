import styled from 'styled-components';

const styledNameCell = styled.div`
  display: grid;
  grid-template-columns: 60px auto;
  grid-template-rows: 60px;

  .text {
    font-weight: bold;
    margin: auto 10px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
`;

export default styledNameCell;
