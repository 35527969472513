import { createGlobalStyle } from 'styled-components';

import fonts from './fonts';

export const GlobalStyle = createGlobalStyle`
   ${fonts}

  html,
  body {
    height: 100%;
    width: 100%;
    font-weight: 400;
    box-sizing: border-box;
  }

  body {
    font-family: 'NB Akademie', Arial, sans-serif;
  }

  #root {
    min-height: 100%;
    min-width: 100%;
  }

  input, select {
    font-family: inherit;
    font-size: inherit;
  }
`;

export default GlobalStyle;
