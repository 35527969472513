import { DialogActions } from '@material-ui/core';
import styled from 'styles/styled-components';

import { DialogProps } from '.';

const StyledDialogActions = styled(DialogActions)`
  justify-content: ${({ closeType, actionButton }: DialogProps) =>
    closeType === 'button' && actionButton
      ? 'space-between'
      : 'flex-end'} !important;
  border-top: 1px solid ${({ theme }) => theme.border.light};
  padding: ${({ type }: DialogProps) =>
    type === 'popover' ? '16px' : '16px 24px'} !important;
`;

export default StyledDialogActions;
