import { DialogContentText } from '@material-ui/core';
import styled from 'styles/styled-components';

import { DialogProps } from '.';

const StyledDialogContentText = styled(DialogContentText)`
  font-size: ${({ type }: DialogProps) =>
    type === 'popover' ? '16px' : '18px'};
`;

export default StyledDialogContentText;
