import styled from 'styles/styled-components';

interface Props {
  marginTop?: string;
}

const Wrapper = styled.div`
  margin-bottom: -1px;
  margin-top: ${({ marginTop }: Props) => marginTop || '0'};
`;

export default Wrapper;
