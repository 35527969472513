import styled from 'styles/styled-components';

import { ButtonProps } from '.';

const Wrapper = styled.div`
  width: ${({ fullWidth }: ButtonProps) => (fullWidth ? '100%' : 'auto')};
  white-space: nowrap;
`;

export default Wrapper;
