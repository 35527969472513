import { PayloadAction } from '@reduxjs/toolkit';
import { createSlice } from 'utils/@reduxjs/toolkit';
import logger from 'utils/logger';
import { ContainerState } from './types';

export const initialState: ContainerState = {
  idToken: null,
  idExp: null,
  refreshToken: null,
  autoLogout: false,
  isAuth: false,
  username: null,
  useCamera: !!process.env.REACT_APP_CAMERA_URL,
  isCalibrated: false,
};

const globalSlice = createSlice({
  name: 'global',
  initialState,
  reducers: {
    changeAfterLoginPath(state, action: PayloadAction<string | undefined>) {
      logger('[reducer/global/afterloginpath/change]', action.payload);
      state.afterLoginPath = action.payload;
    },
    login(state, action: PayloadAction<Partial<ContainerState>>) {
      logger('[reducer/global/login]', action.payload);
      state.idToken = action.payload.idToken;
      state.refreshToken = action.payload.refreshToken;
      state.autoLogout = false;
      state.isAuth = true;
      state.isCalibrated =
        action.payload.isCalibrated || initialState.isCalibrated;
      state.calibrationProtectionPhoto =
        action.payload.calibrationProtectionPhoto;
      state.calibrationVerificationPhoto =
        action.payload.calibrationVerificationPhoto;
    },
    loginSuccess(state, action: PayloadAction<Partial<ContainerState>>) {
      logger('[reducer/global/login/success]');
      state.username = action.payload.username;
      state.userId = action.payload.userId;
      state.idExp = action.payload.idExp;
    },
    refresh() {
      logger('[reducer/global/refresh]');
    },
    refreshSuccess(state, action: PayloadAction<string>) {
      logger('[reducer/global/refresh/success] POST-REFRESH');
      state.idToken = action.payload;
    },
    logout(state) {
      logger('[reducer/global/logout]');
      state.isAuth = false;
      state = { ...initialState };
    },
    autoLogout(state, action: PayloadAction<boolean>) {
      logger('[reducer/global/logout/automatic]', action.payload);
      state.autoLogout = action.payload;
    },
    relogin() {
      logger('[reducer/global/relogin]');
    },
    changeCalibration(state, action: PayloadAction<boolean>) {
      logger('[reducer/global/calibration/change]');
      state.isCalibrated = action.payload;
    },
    setCalibrationProtectionPhoto(
      state,
      action: PayloadAction<string | undefined>,
    ) {
      logger('[reducer/global/calibration/protection/set]');
      state.calibrationProtectionPhoto = action.payload;
    },
    setCalibrationVerificationPhoto(
      state,
      action: PayloadAction<string | undefined>,
    ) {
      logger('[reducer/global/calibration/verification/set]');
      state.calibrationVerificationPhoto = action.payload;
    },
  },
});

export const { actions, reducer, name: sliceKey } = globalSlice;
