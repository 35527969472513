import { NodeWithChildren, StyleableComponent } from '../../types';
import styled, { css } from 'styles/styled-components';

export interface H1Props extends NodeWithChildren, StyleableComponent {
  spacing?: 'none' | 'top' | 'bottom';
}

export const H1 = styled.h1`
  font-size: ${({ theme }) => theme.fontSize.h1};
  font-weight: 100;
  ${({ spacing }: H1Props) => {
    if (spacing === 'none') {
      return css`
        margin: 0;
      `;
    } else if (spacing === 'bottom') {
      return css`
        margin-top: 0;
      `;
    } else if (spacing === 'top') {
      return css`
        margin-bottom: 0;
      `;
    }
    return undefined;
  }}
`;

export default H1;
