import { createMuiTheme } from '@material-ui/core/styles';

const muiTheme = createMuiTheme({
  palette: {
    common: { black: '#000', white: '#fff' },
    background: { paper: '#fff', default: '#fff' },
    primary: {
      light: '#00C7D9',
      main: '#007580',
      dark: '#00454D',
      contrastText: '#fff',
    },
    secondary: {
      light: '#fff',
      main: '#f2f2f2',
      dark: '#fff',
    },
    text: {
      primary: '#1A1A1A',
    },
  },
  typography: {
    fontFamily: '"NB Akademie", "Helvetica", "Arial", sans-serif',
    fontSize: 16,
  },
  overrides: {
    MuiPaper: {
      rounded: {
        borderRadius: '4px',
      },
    },
    MuiButton: {
      root: {
        padding: 'calc(1em - 1px) calc(1.5em - 1px)',
        lineHeight: '100%',
        fontWeight: 'bold',
        textTransform: 'none',
        borderRadius: '4px',
      },
      containedSecondary: {
        background:
          'linear-gradient(180deg, #FFFFFF 0%, #FFFFFF 51.04%, #F2F2F2 100%)',
        border: '1px solid #B3B3B3',
      },
    },
    MuiInput: {
      formControl: {
        'label + &': {
          marginTop: 0,
        },
      },
      underline: {
        '&:before, &:after': {
          content: 'none',
        },
      },
    },
    MuiOutlinedInput: {
      root: {
        fontSize: '1em',
        height: '3rem',
        background: '#F2F2F2',
        borderRadius: '4px',
        boxShadow: 'inset 0px 2px 4px rgba(0, 0, 0, 0.05)',

        '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
          borderColor: '#999999',
          borderWidth: '1px',
        },
        '&:hover .MuiOutlinedInput-notchedOutline': {
          borderColor: '#999999',
        },
      },
      input: {
        padding: '14px 16px 13px',
        lineHeight: '130%',
      },
      notchedOutline: {
        borderColor: 'transparent',
      },
    },
    MuiSelect: {
      select: {
        border: '1px solid #CCCCCC',
        borderRadius: '4px',
        padding: '14px',
        paddingRight: '24px',
      },
      icon: {
        right: '12px',
      },
    },
    MuiMenuItem: {
      root: {
        fontSize: '1em',
        color: '#4d4d4d',
      },
    },
    MuiTab: {
      root: {
        textTransform: 'none',
        padding: '26px 16px 25px',
        lineHeight: 'normal',
        '&:hover': {
          background: '#F2F2F2',
        },
      },
    },
    MuiTabs: {
      indicator: {
        background: '#1A1A1A',
      },
    },
    MuiTableCell: {
      head: {
        fontSize: '14px',
        padding: 0,
        border: 'none',
        boxShadow: 'inset -1px -2px 0 #DCE5E4',
        transition: 'background .6s',
        '&:hover': {
          background: '#F5F7F7',
          transition: 'background .2s',
        },
      },
      body: {
        padding: '16px 24px',
      },
    },
    MuiTableSortLabel: {
      root: {
        width: '100%',
        color: '#7a7a7a',
        fontWeight: 'bold',
        textTransform: 'uppercase',
        padding: '16px 24px',
        lineHeight: 'normal',
      },
      active: {
        color: '#4D4D4D !important',
        boxShadow: 'inset 0 -2px 0 #1a1a1a',
      },
    },
  },
});

export default muiTheme;
