import styled, {
  css,
  theme,
  ButtonPalette,
  ColorStates,
} from 'styles/styled-components';
import { Button, withStyles } from '@material-ui/core';
import { CSSProperties } from 'styled-components';
import { StylesProps } from '.';

interface MainColors {
  default: string;
  hover: string;
}

interface MainPalette {
  color: MainColors;
  background: MainColors;
  border: MainColors;
}

const readonlyStyles = css`
  pointer-events: none;
`;

const loadingStyles = css`
  pointer-events: none;
`;

const GetColors = (
  states: ColorStates,
  disabled?: boolean,
  readonly?: boolean,
): MainColors => {
  let colors: MainColors = {
    default: states.default,
    hover: states.hover,
  };
  if (readonly) {
    colors = {
      default: states.readonly || states.disabled,
      hover: states.readonly || states.disabled,
    };
  } else if (disabled) {
    colors = {
      default: states.disabled,
      hover: states.disabled,
    };
  }
  return colors;
};

const GetColorPalette = (
  palette: ButtonPalette,
  disabled?: boolean,
  readonly?: boolean,
): MainPalette => {
  return {
    color: GetColors(palette.color, disabled, readonly),
    background: GetColors(palette.background, disabled, readonly),
    border: GetColors(palette.border, disabled, readonly),
  };
};

export const StyledButton = (props: StylesProps) => {
  const { primary, secondary, success, danger, size } = theme.button;
  const height = props.large
    ? size.large
    : props.small
    ? size.small
    : size.default;

  let palette: MainPalette;
  switch (props.styleType) {
    default:
    case 'primary':
      palette = GetColorPalette(primary, props.disabled, props.readonly);
      break;
    case 'secondary':
      palette = GetColorPalette(secondary, props.disabled, props.readonly);
      break;
    case 'success':
      palette = GetColorPalette(success, props.disabled, props.readonly);
      break;
    case 'danger':
      palette = GetColorPalette(danger, props.disabled, props.readonly);
      break;
  }

  const rootStyles: CSSProperties = {
    color: `${palette.color.default} !important`,
    background: `${palette.background.default} !important`,
    backgroundColor: `${palette.background.default} !important`,
  };
  if (palette.background.default !== palette.border.default) {
    rootStyles.borderWidth = '1px';
    rootStyles.borderStyle = 'solid';
    rootStyles.borderColor = palette.border.default;
  }
  if (props.readonly) {
    rootStyles.boxShadow = 'none';
  }

  // Hover styles
  const hoverStyles: CSSProperties = {
    color: palette.color.hover,
    backgroundColor: `${palette.background.hover} !important`,
  };
  if (palette.background.hover !== palette.border.hover) {
    hoverStyles.borderWidth = '1px';
    hoverStyles.borderStyle = 'solid';
    hoverStyles.borderColor = palette.border.hover;
  }

  const ButtonWithStyles = withStyles({
    root: {
      ...rootStyles,
      height: height,
      padding: 'calc(1em - 1px) calc(1.5em - 1px)',
      lineHeight: '100%',
      fontWeight: 'bold',
      textTransform: 'none',
      borderRadius: theme.borderRadius,

      '&:hover': {
        ...hoverStyles,
      },
    },
  })(Button);

  return styled(ButtonWithStyles)`
    ${props.readonly && readonlyStyles}
    ${({ loading }: StylesProps) => loading && loadingStyles}
  `;
};

export default StyledButton;
