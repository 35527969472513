import { Breadcrumbs, withStyles } from '@material-ui/core';
import React from 'react';
import styled, { css } from 'styles/styled-components';
import Link from '../Link';
import { NodeWithChildren, StyleableComponent } from '../../types';

export interface BreadcrumbLink {
  title: string;
  to: string;
}

export interface PageTitleProps extends NodeWithChildren, StyleableComponent {
  secondaryElement?: any;
  spacing?: 'none' | 'top' | 'bottom';
  breadcrumbs?: BreadcrumbLink[];
}

const StyledPageTitle = styled.div`
  color: ${({ theme }) => theme.color.default};
  font-size: ${({ theme }) => theme.fontSize.pageTitle};
  height: 96px;
  line-height: 96px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  ${({ spacing }: PageTitleProps) => {
    if (spacing === 'none') {
      return css`
        margin: 0;
      `;
    } else if (spacing === 'bottom') {
      return css`
        margin-top: 0;
      `;
    } else if (spacing === 'top') {
      return css`
        margin-bottom: 0;
      `;
    }
    return undefined;
  }}
`;

const StyledLink = styled(Link)`
  font-size: ${({ theme }) => theme.fontSize.pageTitle};
  color: #999;
`;

const Wrapper = styled.div`
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: space-between;
`;

const StyledBreadcrumbs = withStyles({
  root: {
    fontSize: '34px',
    color: '#999',
    overflow: 'hidden',
  },
  ol: {
    flexWrap: 'nowrap',
  },
  li: {
    '&:last-child': {
      overflow: 'hidden',
    },
  },
})(Breadcrumbs);

export const PageTitle = (props: PageTitleProps) => {
  const titleText =
    typeof props.children === 'string' ? props.children : undefined;
  const title = (
    <StyledPageTitle
      spacing={props.spacing}
      title={props.breadcrumbs ? undefined : titleText}
    >
      {props.breadcrumbs ? (
        <StyledBreadcrumbs>
          {props.breadcrumbs.map((b, i) => (
            <StyledLink key={i} to={b.to}>
              {b.title}
            </StyledLink>
          ))}
          <StyledPageTitle title={titleText}>{props.children}</StyledPageTitle>
        </StyledBreadcrumbs>
      ) : (
        props.children
      )}
    </StyledPageTitle>
  );

  let element = title;
  if (props.secondaryElement) {
    element = (
      <Wrapper className={props.className}>
        {title}
        {props.secondaryElement}
      </Wrapper>
    );
  }
  return element;
};

export default PageTitle;
