import { DialogTitle } from '@material-ui/core';
import styled from 'styles/styled-components';

import { DialogProps } from '.';

const StyledDialogTitle = styled(DialogTitle)`
  font-size: ${({ type }: DialogProps) =>
    type === 'popover' ? '18px' : '24px'};
  border-bottom: 1px solid ${({ theme }) => theme.border.neutral};
`;

export default StyledDialogTitle;
