import React from 'react';
import styled from 'styles/styled-components';
import image from './assets/image.png';
import Wrapper from './Wrapper';

const StyledImg = styled.img`
  height: 100%;
  width: 100%;
  object-fit: cover;
  object-position: center;
`;

export interface SideImageProps {
  src?: string;
}

export const SideImage = (props: SideImageProps) => (
  <Wrapper>
    <StyledImg src={props.src || image} alt="Login picture" />
  </Wrapper>
);

export default SideImage;
