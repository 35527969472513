import styled from 'styles/styled-components';

const StyledToolbar = styled.div`
  display: grid;
  grid-auto-flow: column;
  grid-template-columns: 1fr auto;
  grid-gap: 8px;
  align-items: center;
  padding-right: 24px;
  border-bottom: 1px solid ${({ theme }) => theme.border.default};
  & > div:first-child {
    padding-right: 0;
  }
`;

export default StyledToolbar;
