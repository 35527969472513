import styled from 'styles/styled-components';

const wrapper = styled.div`
  width: 60%;
  height: 100%;
  background: #000;

  @media (max-width: 500px) {
    display: none;
  }
`;

export default wrapper;
