import {
  Menu as MuiMenu,
  MenuProps as MuiMenuProps,
  withStyles,
} from '@material-ui/core';
import { StyleableComponent } from '../../types';
import React, { ReactNode } from 'react';
import styled, { theme } from 'styles/styled-components';
import { Link, LinkProps } from '../Link';

const StyledMenu = withStyles({
  paper: {
    border: '1px solid #d3d4d5',
    borderRadius: theme.borderRadius,
    width: '240px',
    boxShadow: '0px 1px 4px rgba(0, 0, 0, 0.04)',
  },
})(MuiMenu);

const StyledLink = styled(Link)`
  width: 100%;
  height: 48px;
  display: grid;
  grid-auto-flow: column;
  grid-gap: 8px;
  padding: 0 24px;
  justify-content: flex-start;
  align-items: center;

  &:hover {
    background: ${theme.background.secondary};
  }
`;

export interface MenuItemProps extends LinkProps {
  icon?: ReactNode;
  onClick?: any;
}

export interface MenuProps extends MuiMenuProps, StyleableComponent {
  menuItems: MenuItemProps[];
}

export const Menu = (props: MenuProps) => {
  const items = props.menuItems.map((menuItemProps, i) => {
    const linkProps: LinkProps = { ...menuItemProps };
    return (
      <StyledLink key={i} {...linkProps}>
        {menuItemProps.children}
      </StyledLink>
    );
  });

  const p: MuiMenuProps = { ...props };
  return <StyledMenu {...p}>{items}</StyledMenu>;
};

export default Menu;
