import { NodeWithChildren, StyleableComponent } from '../../../types';
import React from 'react';
import styled from 'styles/styled-components';
import { CardRow, CardRowProps } from '../Row';
import CardTitle from './Title';

export interface CardHeaderProps
  extends CardRowProps,
    NodeWithChildren,
    StyleableComponent {
  title?: string;
  secondaryText?: string;
  secondaryElement?: JSX.Element;
  height?: string;
}

const Wrapper = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
  height: ${({ height }: { height?: string }) => height || '2rem'};
`;

const StyledSecondary = styled.div`
  display: grid;
  grid-auto-flow: column;
  grid-gap: 16px;
  align-items: center;
`;

const SecondaryText = styled.div`
  color: ${({ theme }) => theme.color.light};
`;

export const CardHeader = (props: CardHeaderProps) => {
  const rowProps: CardRowProps = { ...props };
  const title = <CardTitle>{props.title || props.children}</CardTitle>;
  const secondaryText = props.secondaryText && (
    <SecondaryText>{props.secondaryText}</SecondaryText>
  );
  const secondaryElement = props.secondaryElement;
  const secondary = (secondaryText || secondaryElement) && (
    <StyledSecondary>
      {secondaryText}
      {secondaryElement}
    </StyledSecondary>
  );
  return (
    <CardRow {...rowProps}>
      <Wrapper height={props.height}>
        {title}
        {secondary}
      </Wrapper>
    </CardRow>
  );
};

export default CardHeader;
