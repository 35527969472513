import { createSelector } from '@reduxjs/toolkit';

import { RootState } from 'types';
import { initialState } from './slice';

const selectDomain = (state: RootState) => state.global || initialState;

export const selectAfterLoginPath = createSelector(
  [selectDomain],
  loginState => loginState.afterLoginPath,
);

export const selectIdToken = createSelector(
  [selectDomain],
  globalState => globalState.idToken,
);

export const selectIdExp = createSelector(
  [selectDomain],
  globalState => globalState.idExp,
);

export const selectRefreshToken = createSelector(
  [selectDomain],
  globalState => globalState.refreshToken,
);

export const selectAutoLogout = createSelector(
  [selectDomain],
  globalState => globalState.autoLogout,
);

export const selectIsAuth = createSelector(
  [selectDomain],
  globalState => globalState.isAuth,
);

export const selectUsername = createSelector(
  [selectDomain],
  globalState => globalState.username,
);

export const selectUserId = createSelector(
  [selectDomain],
  globalState => globalState.userId,
);

export const selectUseCamera = createSelector(
  [selectDomain],
  globalState => globalState.useCamera,
);

export const selectIsCalibrated = createSelector(
  [selectDomain],
  globalState => globalState.isCalibrated,
);

export const selectCalibrationProtectionPhoto = createSelector(
  [selectDomain],
  globalState => globalState.calibrationProtectionPhoto,
);

export const selectCalibrationVerificationPhoto = createSelector(
  [selectDomain],
  globalState => globalState.calibrationVerificationPhoto,
);
