import Light from './nb_akademie_light-webfont.ttf';
import Regular from './nb_akademie_regular-webfont.ttf';
import Medium from './nb_akademie_medium-webfont.ttf';
import Bold from './nb_akademie_bold-webfont.ttf';
import Black from './nb_akademie_black-webfont.ttf';

import LightItalic from './nb_akademie_light_italic-webfont.ttf';
import RegularItalic from './nb_akademie_italic-webfont.ttf';
import MediumItalic from './nb_akademie_medium_italic-webfont.ttf';
import BoldItalic from './nb_akademie_bold_italic-webfont.ttf';
import BlackItalic from './nb_akademie_black_italic-webfont.ttf';

export default {
  Light,
  Regular,
  Medium,
  Bold,
  Black,
  LightItalic,
  RegularItalic,
  MediumItalic,
  BoldItalic,
  BlackItalic,
};
