import React from 'react';
import Wrapper from './Wrapper';
import StyledIcon from './StyledIcon';
import StyledMessage from './StyledMessage';
import { NodeWithChildren, StyleableComponent } from '../../types';

export interface BannerProps extends NodeWithChildren, StyleableComponent {
  type: 'info' | 'success' | 'danger';
  useIcon?: boolean;
  primary?: boolean;
  height?: string;
  fullWidth?: boolean;
  wrap?: boolean;
}

export const Banner = (props: BannerProps) => {
  return (
    <Wrapper {...props}>
      {props.useIcon && <StyledIcon {...props} />}
      <StyledMessage {...props}>{props.children}</StyledMessage>
    </Wrapper>
  );
};

export default Banner;
