import { NodeWithChildren, StyleableComponent } from '../../types';
import React from 'react';
import styled from 'styles/styled-components';

export interface HRProps extends NodeWithChildren, StyleableComponent {}

const Wrapper = styled.div`
  width: 100%;
  display: flex;
  margin: 8px 0;
  align-items: center;
`;

const StyledHR = styled.div`
  width: 100%;
  flex: 1;
  border-bottom: 1px solid ${({ theme }) => theme.border.default};
`;

const Text = styled.div`
  padding: 0 4px;
  color: ${({ theme }) => theme.color.light};
`;

export const HR = (props: HRProps) => (
  <Wrapper>
    <StyledHR />
    {props.children && (
      <React.Fragment>
        <Text>{props.children}</Text>
        <StyledHR />
      </React.Fragment>
    )}
  </Wrapper>
);

export default HR;
