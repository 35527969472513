/**
 * Console log helper
 * @param messages Messages
 */
const logger = (...messages: any[]) => {
  if (process.env.REACT_APP_LOGGER || process.env.NODE_ENV !== 'production') {
    console.log(...messages);
  }
};

export default logger;
