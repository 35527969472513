import React, { ReactElement } from 'react';
import Button from '../Button';
import {
  IconButton,
  Dialog as MuiDialog,
  DialogContent,
  DialogProps as MuiDialogProps,
  Popover,
  PopoverProps as MuiPopoverProps,
  withStyles,
} from '@material-ui/core';
import Title from './Title';
import ContentText from './ContentText';
import Actions from './Actions';
import { ReactComponent as CloseIcon } from './assets/close.svg';
import { NodeWithChildren, StyleableComponent } from '../../types';

export type CloseButtonType = 'button' | 'x';

export interface DialogProps extends NodeWithChildren, StyleableComponent {
  type?: 'dialog' | 'popover';
  title?: string;
  text?: string;
  closeType?: CloseButtonType;
  closeText?: string;
  actionButton?: ReactElement<typeof Button>;
  open: boolean;
  onClose?: any;
  hideBackdrop?: boolean;
  dialogOptions?: Partial<MuiDialogProps>;
  popoverOptions?: Partial<MuiPopoverProps>;
}

const StyledPopover = withStyles({
  paper: {
    maxWidth: '400px',
  },
})(Popover);

const StyledIconButton = withStyles({
  root: {
    position: 'absolute',
    top: '0',
    right: '0',
    margin: '12px',
  },
})(IconButton);

const StyledDialogContent = withStyles({
  root: {
    paddingTop: '0',
    paddingBottom: '0',
  },
})(DialogContent);

export const Dialog = (props: DialogProps) => {
  const content = (
    <>
      {props.title && <Title {...props}>{props.title}</Title>}
      {props.onClose && props.closeType === 'x' && (
        <StyledIconButton onClick={props.onClose}>
          <CloseIcon />
        </StyledIconButton>
      )}
      <StyledDialogContent>
        {props.text && <ContentText {...props}>{props.text}</ContentText>}
        {props.children}
      </StyledDialogContent>
      {(props.closeType === 'button' || props.actionButton) && (
        <Actions {...props}>
          {props.closeType === 'button' && props.onClose && (
            <Button onClick={props.onClose} styleType="secondary">
              {props.closeText || 'Cancel'}
            </Button>
          )}
          {props.actionButton}
        </Actions>
      )}
    </>
  );

  const element =
    props.type === 'popover' ? (
      <StyledPopover
        open={props.open}
        onClose={props.onClose}
        {...props.popoverOptions}
        className={props.className}
      >
        {content}
      </StyledPopover>
    ) : (
      <MuiDialog
        open={props.open}
        onClose={props.onClose}
        {...props.dialogOptions}
        className={props.className}
      >
        {content}
      </MuiDialog>
    );

  return element;
};

export default Dialog;
