import React from 'react';
import { useForm, FormContextValues, UseFormOptions } from 'react-hook-form';

import StyledForm from './StyledForm';
import Input, { InputProps } from './Input';
import Button, { ButtonProps } from '../Button';
import { Alert } from '@material-ui/lab';
import styled from 'styled-components';
import { NodeWithChildren, StyleableComponent } from '../../types';

export interface FormStyleProps {
  inline?: boolean;
}

export interface FormProps
  extends FormStyleProps,
    NodeWithChildren,
    StyleableComponent {
  formContextValues?: FormContextValues<any>;
  formOptions?: UseFormOptions<any>;
  onSubmit?: any;
  inputs: InputProps[];
  submitButton?: ButtonProps;
  loading?: boolean;
  error?: string | null;
}

const SubmitWrapper = styled.div`
  padding: ${({ inline }: FormStyleProps) => (inline ? '24px 0 0' : '10px 0')};
  &:last-child {
    padding-bottom: 0;
  }
`;

export const Form = (props: FormProps) => {
  let formContextValues = useForm(props.formOptions);
  if (props.formContextValues) {
    formContextValues = props.formContextValues;
  }
  const { register, handleSubmit, errors } = formContextValues;

  const onSubmitHandler = (data: any) => {
    props.onSubmit(data);
  };

  const errorMessage = props.error && (
    <Alert severity="error">{props.error}</Alert>
  );

  const inputs = props.inputs.map(iProps => {
    const validation = iProps.validation || {};
    const error = errors[iProps.name];
    const errorMessage = error && errors[iProps.name].message;
    const p = {
      ...iProps,
      reference: register(validation),
      error,
      errorMessage,
      inline: props.inline,
    };
    return <Input key={iProps.name} {...p} />;
  });

  const submit = props.submitButton && (
    <SubmitWrapper inline={props.inline}>
      <Button {...props.submitButton} type="submit" loading={props.loading}>
        {props.submitButton.text}
      </Button>
    </SubmitWrapper>
  );

  const inline = (props.inline && inputs.length === 1 && 'inline') || '';
  const className = `${props.className || ''} ${inline}`;

  return (
    <StyledForm
      onSubmit={handleSubmit(onSubmitHandler)}
      noValidate
      autoComplete="off"
      className={className}
    >
      {errorMessage}
      {inputs}
      {submit}
      {props.children}
    </StyledForm>
  );
};

export default Form;

export { useForm } from 'react-hook-form';
