/**
 * Combine all reducers in this file and export the combined reducers.
 */

import { AnyAction, combineReducers } from '@reduxjs/toolkit';

import { InjectedReducersType } from 'utils/types/injector-typings';

import { actions } from 'app/store/slice';

/**
 * Merges the main reducer with the router state and dynamically injected reducers
 */
export function createReducer(injectedReducers: InjectedReducersType = {}) {
  // Initially we don't have any injectedReducers, so returning identity function to avoid the error
  if (Object.keys(injectedReducers).length === 0) {
    return state => state;
  } else {
    return (state, action: AnyAction) => {
      // Logout action reset redux store
      if (action.type === actions.logout.type) {
        state = undefined;
      }
      return combineReducers({
        ...injectedReducers,
      })(state, action);
    };
  }
}
