import styled from 'styles/styled-components';

import {
  Input,
  OutlinedInput,
  FormControl,
  IconButton,
} from '@material-ui/core';

import { inputStyles, fontStyles } from './inputStyles';

export const StyledInput = styled(Input)`
  ${fontStyles}
`;

export const StyledOutlinedInput = styled(OutlinedInput)`
  ${inputStyles}
  ${fontStyles}
`;

export const StyledFormControl = styled(FormControl)`
  ${inputStyles}
`;

export const StyledInputButton = styled(IconButton)`
  width: 44px;
  height: 44px;
`;
