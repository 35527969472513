import { NodeWithChildren, StyleableComponent } from '../../../../types';
import React from 'react';
import styled from 'styles/styled-components';

export interface CardListItemProps
  extends NodeWithChildren,
    StyleableComponent {
  label?: string;
}

const Wrapper = styled.li`
  display: flex;
  flex-direction: column;
  width: 100%;

  &:not(:last-of-type) {
    margin-bottom: 1rem;
  }
`;

const Label = styled.div`
  font-size: ${({ theme }) => theme.fontSize.sm};
  line-height: 100%;
  opacity: 0.6;
`;

const Value = styled.div``;

export const CardListItem = (props: CardListItemProps) => (
  <Wrapper className={props.className}>
    {props.label && <Label>{props.label}</Label>}
    <Value>{props.children}</Value>
  </Wrapper>
);

export default CardListItem;
