import { NodeWithChildren, StyleableComponent } from '../../../types';
import React from 'react';
import styled from 'styles/styled-components';

export interface InputWrapperProps
  extends NodeWithChildren,
    StyleableComponent {
  primary?: boolean;
  inline?: boolean;
  autoHeight?: boolean;
}

const HEIGHT = '100px';
const HEIGHT_LARGE = '132px';

const WrapperDiv = styled.div`
  width: 100%;
  height: ${({ primary, inline, autoHeight }: InputWrapperProps) =>
    inline || autoHeight ? 'auto' : primary ? HEIGHT_LARGE : HEIGHT};
  padding: ${({ inline }) => (inline ? '0 16px 0 0' : '10px 0')};
  box-sizing: content-box;
  &:first-child {
    padding-top: 0;
  }
  &:last-child {
    padding-bottom: 0;
  }
`;

const wrapper = (props: InputWrapperProps) => <WrapperDiv {...props} />;

export default wrapper;
