import React, { useState, ReactNode } from 'react';
import {
  InputAdornment,
  FormHelperText,
  MenuItem as MuiMenuItem,
  withStyles,
} from '@material-ui/core';
import { VisibilityOutlined, VisibilityOffOutlined } from '@material-ui/icons';
import { ValidationOptions } from 'react-hook-form';

import Wrapper from './wrapper';
import {
  StyledOutlinedInput,
  StyledFormControl,
  StyledInputButton,
} from './StyledInput';
import { StyledSelect } from './StyledSelect';
import { ReactComponent as ArrowDownIcon } from './assets/chevron-down.svg';
import { NodeWithChildren, StyleableComponent } from '../../../types';
import { theme } from 'styles/styled-components';

export type InputTypes = 'text' | 'email' | 'password' | 'select' | 'number';

export interface SelectOptionProps {
  value?: string | number | string[];
  text: string;
}

export interface InputProps extends NodeWithChildren, StyleableComponent {
  id?: string;
  name: string;
  type?: InputTypes;
  onChange?: (event: any) => void;
  value?: any;
  label?: string;
  placeholder?: string;
  hint?: string;
  validation?: ValidationOptions;
  error?: boolean;
  errorMessage?: string;
  reference?: any;
  primary?: boolean;
  inline?: boolean;
  autoHeight?: boolean;
  inputProps?: any;
  width?: string;
  options?: SelectOptionProps[];
}

export const MenuItem = withStyles({
  root: {
    fontSize: '1em',
    color: theme.color.light,
  },
})(MuiMenuItem);

export const FormInput = (props: InputProps) => {
  const [showPassword, setShowPassword] = useState<Boolean>(false);

  const helperText = props.errorMessage || props.hint || null;
  const inputProps = { primary: props.primary, ...props.inputProps };

  let element: ReactNode;
  switch (props.type) {
    default:
    case 'email':
    case 'text':
      element = (
        <StyledFormControl
          error={props.error}
          fullWidth={true}
          width={props.width}
        >
          <label>{props.label}</label>
          <StyledOutlinedInput
            name={props.name}
            value={props.value}
            placeholder={props.placeholder}
            onChange={props.onChange}
            inputRef={props.reference}
            error={props.error}
            inputProps={inputProps}
            fullWidth={true}
            type={props.type}
            width={props.width}
          />
          {helperText && <FormHelperText>{helperText}</FormHelperText>}
        </StyledFormControl>
      );
      break;
    case 'number':
      const numberInputProps = {
        ...inputProps,
        style: {
          textAlign: 'right',
          boxSizing: 'border-box',
          paddingRight: '6px',
          ...inputProps.style,
        },
      };
      element = (
        <StyledFormControl error={props.error} width={props.width}>
          <label>{props.label}</label>
          <StyledOutlinedInput
            name={props.name}
            value={props.value}
            placeholder={props.placeholder}
            onChange={props.onChange}
            inputRef={props.reference}
            error={props.error}
            inputProps={numberInputProps}
            type={props.type}
            width={props.width}
          />
          {helperText && <FormHelperText>{helperText}</FormHelperText>}
        </StyledFormControl>
      );
      break;
    case 'password':
      const showPassportHandler = () => {
        setShowPassword(prevValue => !prevValue);
      };
      element = (
        <StyledFormControl
          error={props.error}
          fullWidth={true}
          width={props.width}
        >
          <label htmlFor="standard-adornment-password">{props.label}</label>
          <StyledOutlinedInput
            type={showPassword ? 'text' : 'password'}
            name={props.name}
            value={props.value}
            onChange={props.onChange}
            inputRef={props.reference}
            inputProps={inputProps}
            width={props.width}
            endAdornment={
              <InputAdornment position="end">
                <StyledInputButton
                  aria-label="toggle password visibility"
                  onClick={showPassportHandler}
                >
                  {showPassword ? (
                    <VisibilityOffOutlined />
                  ) : (
                    <VisibilityOutlined />
                  )}
                </StyledInputButton>
              </InputAdornment>
            }
          />
          {helperText && <FormHelperText>{helperText}</FormHelperText>}
        </StyledFormControl>
      );
      break;
    case 'select':
      const menuItems: JSX.Element[] = [];
      if (props.placeholder) {
        menuItems.push(
          <MenuItem key={-1} disabled selected={!props.value}>
            {props.placeholder}
          </MenuItem>,
        );
      }
      if (props.options) {
        props.options.forEach((option, index) => {
          menuItems.push(
            <MenuItem key={index} value={option.value}>
              {option.text}
            </MenuItem>,
          );
        });
      }
      element = (
        <StyledFormControl error={props.error} fullWidth={true}>
          <label>{props.label}</label>
          <StyledSelect
            name={props.name}
            inputRef={props.reference}
            onChange={props.onChange}
            inputProps={inputProps}
            value={props.value}
            displayEmpty={!!props.placeholder}
            IconComponent={ArrowDownIcon}
            variant="outlined"
          >
            {menuItems}
          </StyledSelect>
          {helperText && <FormHelperText>{helperText}</FormHelperText>}
        </StyledFormControl>
      );
      break;
  }

  return (
    <Wrapper
      primary={props.primary}
      inline={props.inline}
      autoHeight={props.autoHeight}
      className={props.className}
    >
      {element}
    </Wrapper>
  );
};

export default FormInput;
