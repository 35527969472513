import React from 'react';
import { Avatar, withStyles } from '@material-ui/core';
import styled from 'styles/styled-components';
import { ReactComponent as ArrowDownIcon } from './assets/chevron-down.svg';
import { Menu, MenuItemProps } from '../../UI/Menu';

export interface UserAvatarProps {
  username: string;
  menuItems?: MenuItemProps[];
}

const Wrapper = styled.div`
  grid-area: user;
  justify-self: flex-end;
  position: relative;
`;

const StyledAvatar = styled(Avatar)`
  background: ${({ theme }) => theme.background.avatar} !important;
  color: ${({ theme }) => theme.color.avatar} !important;
  text-transform: uppercase;
  font-size: 14px !important;
  font-weight: bold;
  line-height: normal !important;
`;

const AvatarWrapper = styled.div`
  display: grid;
  grid-template-columns: 40px fit-content(300px) 24px;
  grid-gap: 10px;
  align-items: center;
  cursor: pointer;

  @media (max-width: 998px) {
    grid-template-columns: 40px 24px;
  }

  @media (max-width: 500px) {
    grid-template-columns: 40px fit-content(190px) 24px;
  }
`;

const StyledUser = styled.span`
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;

  @media (min-width: 500px) and (max-width: 998px) {
    display: none;
  }
`;

const StyledMenu = withStyles({
  paper: {
    marginTop: '10px',
  },
})(Menu);

export const UserAvatar = (props: UserAvatarProps) => {
  const [showLogout, setShowLogout] = React.useState(false);
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);

  const avatarClickHandler = (event: React.MouseEvent<HTMLElement>) => {
    setShowLogout(prev => !prev);
    setAnchorEl(event.currentTarget);
  };

  const closeHandler = () => {
    setShowLogout(false);
    setAnchorEl(null);
  };

  return (
    <Wrapper>
      <AvatarWrapper onClick={avatarClickHandler}>
        <StyledAvatar>
          {props.username.length && props.username[0]}
        </StyledAvatar>
        <StyledUser>{props.username}</StyledUser>
        <span>
          <ArrowDownIcon />
        </span>
      </AvatarWrapper>
      {props.menuItems && (
        <StyledMenu
          elevation={0}
          getContentAnchorEl={null}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'center',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'center',
          }}
          keepMounted
          open={showLogout}
          anchorEl={anchorEl}
          onClose={closeHandler}
          menuItems={props.menuItems}
        />
      )}
    </Wrapper>
  );
};

export default UserAvatar;
