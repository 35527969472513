import jwt from 'jsonwebtoken';

/**
 * Id token
 */
export const ID_TOKEN_KEY = 'idToken';

export const getIdToken = () => {
  return localStorage.getItem(ID_TOKEN_KEY);
};

export const setIdToken = (idToken: string) => {
  localStorage.setItem(ID_TOKEN_KEY, idToken);
};

export const removeIdToken = () => {
  localStorage.removeItem(ID_TOKEN_KEY);
};

export const decodeToken = async (token: string) => {
  return jwt.decode(token);
};

/**
 * Refresh token
 */
export const REFREH_TOKEN_KEY = 'refreshToken';

export const getRefreshToken = () => {
  return localStorage.getItem(REFREH_TOKEN_KEY);
};

export const setRefreshToken = (refreshToken: string) => {
  localStorage.setItem(REFREH_TOKEN_KEY, refreshToken);
};

export const removeRefreshToken = () => {
  localStorage.removeItem(REFREH_TOKEN_KEY);
};

/**
 * Protection camera calibration photo
 */
export const PROTECTION_CAMERA_KEY = 'protectionCamera';

export const getProtectionCamera = () => {
  return sessionStorage.getItem(PROTECTION_CAMERA_KEY);
};

export const setProtectionCamera = (photo: string) => {
  sessionStorage.setItem(PROTECTION_CAMERA_KEY, photo);
};

export const removeProtectionCamera = () => {
  sessionStorage.removeItem(PROTECTION_CAMERA_KEY);
};

/**
 * Verification camera calibration photo
 */
export const VERIFICATION_CAMERA_KEY = 'verificationCamera';

export const getVerificationCamera = () => {
  return sessionStorage.getItem(VERIFICATION_CAMERA_KEY);
};

export const setVerificationCamera = (photo: string) => {
  sessionStorage.setItem(VERIFICATION_CAMERA_KEY, photo);
};

export const removeVerificationCamera = () => {
  sessionStorage.removeItem(VERIFICATION_CAMERA_KEY);
};
