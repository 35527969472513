import React, { ReactNode } from 'react';
import styled from 'styled-components';
import { TabPanel } from '@material-ui/lab';
import { StyleableComponent } from '../../../types';

export interface TabPanelProps {
  value: string;
  content: ReactNode;
}

export interface TabPanelsProps extends StyleableComponent {
  panels: TabPanelProps[];
}

const StyledTabPanel = styled(TabPanel)`
  padding: 0 !important;
`;

export const TabPanels = (props: TabPanelsProps) => {
  const panels = props.panels.map(panel => (
    <StyledTabPanel
      key={panel.value}
      value={panel.value}
      className={props.className}
    >
      {panel.content}
    </StyledTabPanel>
  ));
  return <React.Fragment>{panels}</React.Fragment>;
};

export default TabPanels;
