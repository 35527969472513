import styled from 'styles/styled-components';

const wrapper = styled.div`
  display: grid;
  grid-template-columns: auto 1.5rem auto 1.5rem auto;
  justify-content: center;
  justify-items: center;
  align-items: center;
  height: 100px;
  margin-top: 1.25rem;
  border-top: 1px solid ${({ theme }) => theme.border.default};
  font-weight: 300;
`;

export default wrapper;
