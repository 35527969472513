import React from 'react';
import { ButtonProps as MuiButtonProps } from '@material-ui/core';
import { NodeWithChildren, StyleableComponent } from '../../types';

import { StyledButton } from './StyledButton';
import Wrapper from './Wrapper';
import LabelWrapper from './LabelWrapper';
import Loading from './Loading';

export interface BasicProps {
  small?: boolean;
  large?: boolean;
  loading?: boolean;
}

export type ButtonStyleType = 'primary' | 'secondary' | 'success' | 'danger';

export interface StylesProps extends BasicProps {
  styleType?: ButtonStyleType;
  disabled?: boolean;
  readonly?: boolean;
}

export interface ButtonProps
  extends StylesProps,
    NodeWithChildren,
    StyleableComponent {
  type?: 'button' | 'submit';
  onClick?: any;
  text?: string;
  icon?: React.ReactElement;
  fullWidth?: boolean;
  ref?: any;
}

interface MaterialButtonProps extends MuiButtonProps, StylesProps {}

export const Button = (props: ButtonProps) => {
  const stylesProps: StylesProps = {
    styleType: props.styleType,
    disabled: props.disabled,
    readonly: props.readonly,
    loading: props.loading,
    small: props.small,
    large: props.large,
  };
  const ButtonElement = StyledButton(stylesProps);

  const onClickHandler = (event: any) => {
    if (props.onClick) {
      props.onClick(event);
    }
  };

  const buttonProps: MaterialButtonProps = {
    ref: props.ref,
    type: props.type || 'button',
    variant: 'contained',
    disabled: props.disabled,
    fullWidth: props.fullWidth || false,
    onClick: onClickHandler,
    small: props.small,
    large: props.large,
    loading: props.loading,
  };

  const loading = props.loading && (
    <Loading size={24} secondary={props.styleType === 'secondary'} />
  );

  return (
    <Wrapper fullWidth={props.fullWidth} className={props.className}>
      {/* 
      // @ts-ignore */}
      <ButtonElement {...buttonProps}>
        <LabelWrapper loading={props.loading} icon={props.icon}>
          <div className="label">
            {props.icon}
            {props.children || props.text}
          </div>
          {loading}
        </LabelWrapper>
      </ButtonElement>
    </Wrapper>
  );
};

export default Button;
