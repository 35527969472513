import React, { ReactNode } from 'react';

import SideImage from '../SideImage';
import MainContainer from './MainContainer';
import Header from '../Header';
import Content from '../Content';
import ContentContainer from '../ContentContainer';
import Footer, { FooterType } from '../Footer';
import { NodeWithChildren, StyleableComponent } from '../../types';
import { MenuItemProps } from 'app/components/UI/Menu';
import { NavLinkProps } from 'app/components/UI/NavLink';

export interface LayoutProps extends NodeWithChildren, StyleableComponent {
  username?: string | null;
  disableNav?: boolean;
  noHeader?: boolean;
  noFooter?: boolean;
  footerType?: FooterType;
  userMenuItems?: MenuItemProps[];
  navLinks?: NavLinkProps[];
  useSideImage?: boolean;
  sideImage?: string;
  customFooter?: ReactNode;
  customNavContent?: React.ReactNode;
  fluidContent?: boolean;
}

export const Layout = (props: LayoutProps) => {
  const content = <Content>{props.children}</Content>;
  const footer = !props.noFooter && (
    <Footer type={props.footerType} customContent={props.customFooter} />
  );
  const layout =
    props.noHeader || !props.username ? (
      <React.Fragment>
        <ContentContainer minWidth={'500px'}>
          {content}
          {footer}
        </ContentContainer>
        {(props.useSideImage || props.sideImage) && (
          <SideImage src={props.sideImage} />
        )}
      </React.Fragment>
    ) : (
      <React.Fragment>
        <Header
          userOptions={{
            username: props.username,
            userMenuItems: props.userMenuItems,
          }}
          navOptions={{
            navLinks: props.navLinks,
            disableNav: props.disableNav,
            customContent: props.customNavContent,
          }}
        />
        <ContentContainer background="secondary" fluid={props.fluidContent}>
          {content}
          {footer}
        </ContentContainer>
      </React.Fragment>
    );
  return (
    <MainContainer direction={props.noHeader ? 'row' : 'column'}>
      {layout}
    </MainContainer>
  );
};

export default Layout;
