import axios, { AxiosError, AxiosRequestConfig, AxiosResponse } from 'axios';
import { store } from 'index';
import { actions } from 'app/store/slice';
import logger from './logger';

// Authorization
const configAuthRequest = async (config: AxiosRequestConfig) => {
  logger('[axios/request]', config);
  const state = store.getState();
  const token = state && state.global && state.global.idToken;
  if (token) {
    config.headers.authorization = token;
  }
  return config;
};
// Response
const configAuthResponse = (response: AxiosResponse) => {
  logger('[axios/response]', response);
  return response;
};
// Response authorization
const configAuthResponseError = (error: AxiosError) => {
  logger('[axios/response/error]', error.response);
  if (error && error.response && error.response.status === 401) {
    const state = store.getState();
    const isAuth = state && state.global && state.global.isAuth;
    if (isAuth) {
      store.dispatch(actions.logout());
    }
  }
  return Promise.reject(error);
};

// Backend
const backendOptions: AxiosRequestConfig = {
  baseURL: process.env.REACT_APP_BACKEND_URL,
};
export const axiosBackend = axios.create(backendOptions);
axiosBackend.interceptors.request.use(configAuthRequest);
axiosBackend.interceptors.response.use(
  configAuthResponse,
  configAuthResponseError,
);

// Camera controller
const cameraOptions: AxiosRequestConfig = {
  baseURL: process.env.REACT_APP_CAMERA_URL,
};
export const axiosCamera = axios.create(cameraOptions);
axiosCamera.interceptors.request.use(configAuthRequest);
axiosCamera.interceptors.response.use(
  configAuthResponse,
  configAuthResponseError,
);

export default axiosBackend;
