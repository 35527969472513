import React, { ReactNode } from 'react';
import { theme } from 'styles/styled-components';
import { Tab as MuiTab, withStyles } from '@material-ui/core';
import { TabList as MuiTabList } from '@material-ui/lab';

import Wrapper from './Wrapper';
import { StyleableComponent } from '../../../types';

const Tab = withStyles({
  root: {
    textTransform: 'none',
    padding: '26px 16px 25px',
    lineHeight: 'normal',
    '&:hover': {
      background: theme.background.secondary,
    },
  },
})(MuiTab);

export interface TabProps extends StyleableComponent {
  label: ReactNode;
  value: any;
}

export interface TabListProps extends StyleableComponent {
  tabs: TabProps[];
  onChange: (value: string) => void;
  marginTop?: string;
}

export const TabList = (props: TabListProps) => {
  const tabs = props.tabs.map(tab => (
    <Tab
      key={tab.value}
      label={tab.label}
      value={tab.value}
      className={props.className}
    />
  ));

  return (
    <Wrapper marginTop={props.marginTop} className={props.className}>
      <MuiTabList onChange={(_e, value) => props.onChange(value)}>
        {tabs}
      </MuiTabList>
    </Wrapper>
  );
};

export default TabList;
