/**
 *
 * App
 *
 * This component is the skeleton around the actual pages, and should only
 * contain code that should be seen on all pages. (e.g. navigation bar)
 */

import React, { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet-async';
import { Switch, Route, useHistory, Redirect } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { useInjectReducer, useInjectSaga } from 'utils/redux-injectors';

import { GlobalStyle } from 'styles/global-styles';

import {
  Layout,
  LayoutProps,
  NavLinkProps,
  MenuItemProps,
} from 'app/components';
import loginImage from './assets/login.png';
import { ReactComponent as LogOutIcon } from './assets/log-out.svg';
import { ReactComponent as VideoIcon } from './assets/video.svg';

import { NotFound } from './containers/NotFound/Loadable';
import { Login } from './containers/Login/Loadable';
import { Logout } from './containers/Logout/Loadable';
import { Calibration } from './containers/Calibration/Loadable';
import { Identification } from './containers/Identification/Loadable';
import { Authenticity } from './containers/Authenticity/Loadable';
import { AuthenticityItem } from './containers/AuthenticityItem/Loadable';

import { reducer, sliceKey, actions } from './store/slice';
import { globalSaga } from './store/saga';
import {
  selectIsCalibrated,
  selectIsAuth,
  selectUsername,
  selectUseCamera,
  selectAfterLoginPath,
} from './store/selectors';

export function App() {
  useInjectReducer({ key: sliceKey, reducer: reducer });
  useInjectSaga({ key: sliceKey, saga: globalSaga });

  const afterLoginPath = useSelector(selectAfterLoginPath);
  const isAuth = useSelector(selectIsAuth);
  const username = useSelector(selectUsername);
  const isCalibrated = useSelector(selectIsCalibrated);
  const useCamera = useSelector(selectUseCamera);

  const dispatch = useDispatch();
  const history = useHistory();

  const [host] = useState(window.location.hostname);

  useEffect(() => {
    dispatch(actions.relogin());
  }, [dispatch]);

  useEffect(() => {
    if (isAuth) {
      if (afterLoginPath) {
        const path =
          useCamera && !isCalibrated ? '/calibration' : afterLoginPath;
        history.replace(path);
        dispatch(actions.changeAfterLoginPath());
      }
    } else {
      const path =
        (window.location.pathname !== '/login' &&
          `${window.location.pathname}${window.location.search}`) ||
        '/identification';
      dispatch(actions.changeAfterLoginPath(path));
      history.push('/login');
    }
  }, [isAuth, afterLoginPath, history, dispatch, useCamera, isCalibrated]);

  const routes = isAuth ? (
    useCamera && !isCalibrated ? (
      // Uncalibrated routes
      <Switch>
        <Route path="/calibration" component={Calibration} />
        <Route path="/logout" component={Logout} />
        <Route path="/tac" render={() => <h1>Terms and conditions</h1>} />
      </Switch>
    ) : (
      // Authenticated routes
      <Switch>
        {useCamera && <Route path="/calibration" component={Calibration} />}
        <Route path="/identification" exact component={Identification} />
        <Route path="/identification/:seriesId" component={Identification} />
        <Route path="/authenticity" exact component={Authenticity} />
        <Route path="/authenticity/:itemId" component={AuthenticityItem} />
        <Route path="/logout" component={Logout} />
        <Route path="/tac" render={() => <h1>Terms and conditions</h1>} />
        <Redirect exact from="/" to="/identification" />
        <Route component={NotFound} />
      </Switch>
    )
  ) : (
    // Public routes
    <Switch>
      <Route path="/login" component={Login} />
      <Route path="/signup" render={() => <h1>Signup page</h1>} />
      <Route path="/tac" render={() => <h1>Terms and conditions</h1>} />
    </Switch>
  );

  // Top navigation links
  let navLinks: NavLinkProps[] = [
    {
      to: '/identification',
      children: 'Identification',
    },
    {
      to: '/authenticity',
      children: 'Authentication',
    },
  ];
  // identification.veracityprotocol.org
  if (host.includes('identification')) {
    navLinks = [
      {
        to: '/identification',
        children: 'Identification',
      },
    ];
  }

  let userMenuItems: MenuItemProps[] = [
    {
      to: '/logout',
      noUnderline: true,
      color: 'danger',
      children: 'Log out',
      icon: <LogOutIcon />,
    },
  ];
  // Enable calibration only with camera
  if (useCamera) {
    userMenuItems.unshift({
      to: '/calibration',
      noUnderline: true,
      color: 'primary',
      children: 'Calibration',
      icon: <VideoIcon />,
    });
  }

  const layoutProps: LayoutProps = {
    sideImage: loginImage,
    noHeader: !isAuth,
    username: username,
    disableNav: useCamera && !isCalibrated,
    navLinks,
    userMenuItems,
  };

  return (
    <>
      <Helmet
        titleTemplate="%s - Explorer"
        defaultTitle="Veracity Protocol Explorer"
      >
        <meta name="description" content="Veracity Protocol Explorer" />
      </Helmet>

      <Layout {...layoutProps}>{routes}</Layout>

      <GlobalStyle />
    </>
  );
}
