import styled from 'styles/styled-components';

const CustomToolbarMiddle = styled.div`
  display: grid;
  grid-gap: 8px;
  grid-auto-flow: column;
  grid-template-columns: auto;
  grid-auto-columns: 1fr;
  justify-items: flex-end;
  align-items: center;
  padding-left: 24px;
  margin-right: -48px;
  z-index: 1;

  .title {
    font-size: 1.5rem;
    font-weight: bold;
  }
`;

export default CustomToolbarMiddle;
