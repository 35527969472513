import { NodeWithChildren, StyleableComponent } from '../../types';
import styled from 'styled-components';

export interface EmProps extends NodeWithChildren, StyleableComponent {}

export const Em = styled.em`
  font-family: 'Baskerville';
`;

export default Em;
