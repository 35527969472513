import React from 'react';

import Wrapper from './Wrapper';
import UserAvatar from './UserAvatar';
import { Logo } from '../../UI/Logo';
import { Nav } from '../../UI/Nav';
import { NavLinkProps } from '../../UI/NavLink';
import { MenuItemProps } from '../../UI/Menu';

export interface HeaderUserOptions {
  username: string;
  userMenuItems?: MenuItemProps[];
}

export interface HeaderNavOptions {
  navLinks?: NavLinkProps[];
  disableNav?: boolean;
  customContent?: React.ReactNode;
}

export interface HeaderProps {
  userOptions?: HeaderUserOptions;
  navOptions?: HeaderNavOptions;
}

export const Header = (props: HeaderProps) => (
  <Wrapper>
    <Logo />
    {props.navOptions &&
      (props.navOptions.customContent || (
        <Nav
          navLinks={props.navOptions.navLinks || []}
          disabled={props.navOptions.disableNav}
        />
      ))}
    {props.userOptions && (
      <UserAvatar
        username={props.userOptions.username}
        menuItems={props.userOptions.userMenuItems}
      />
    )}
  </Wrapper>
);

export default Header;
