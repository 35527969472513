import React from 'react';
import { theme } from 'styles/styled-components';
import { Chip, ChipProps as MuiChipProps, withStyles } from '@material-ui/core';

export type StatusChipType =
  | 'neutral'
  | 'success'
  | 'danger'
  | 'info'
  | 'special';

export interface StatusChipProps extends MuiChipProps {
  type: StatusChipType;
}

export const StatusChip = (props: StatusChipProps) => {
  let color = theme.color.neutral;
  let background = theme.background.neutral;
  switch (props.type) {
    default:
    case 'neutral':
      break;
    case 'special':
      color = theme.color.special;
      background = theme.background.special;
      break;
    case 'success':
      color = theme.color.success;
      background = theme.background.success;
      break;
    case 'danger':
      color = theme.color.danger;
      background = theme.background.danger;
      break;
    case 'info':
      color = theme.color.info;
      background = theme.background.info;
      break;
  }
  const StyledChip = withStyles({
    root: {
      borderRadius: '4px',
      color: color,
      backgroundColor: background,
    },
    label: {
      paddingLeft: '8px',
      paddingRight: '8px',
    },
  })(Chip);

  const chipProps: MuiChipProps = { ...props };

  return <StyledChip {...chipProps} />;
};

export default StatusChip;
