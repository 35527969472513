import React from 'react';
import styled, { css, IThemeInterface } from 'styles/styled-components';
import { ThemedStyledProps } from 'styled-components';
import {
  CircularProgress,
  CircularProgressProps,
  Modal,
} from '@material-ui/core';
import { NodeWithChildren, StyleableComponent } from '../../types';

export interface LoadingProps extends NodeWithChildren, StyleableComponent {
  page?: boolean;
  content?: boolean;
  parent?: boolean;
  open?: boolean;
  primary?: boolean;
  secondary?: boolean;
  size?: CircularProgressProps['size'];
  options?: CircularProgressProps;
}

const Wrapper = styled.div`
  display: grid;
  grid-gap: 16px;
  grid-auto-flow: column;
  grid-auto-columns: auto;
  justify-content: center;
  align-items: center;
  color: ${({
    primary,
    secondary,
    theme,
  }: ThemedStyledProps<LoadingProps, IThemeInterface>) =>
    primary
      ? theme.button.primary.color.default
      : secondary
      ? theme.color.secondary
      : theme.button.secondary.color.default} !important;
  ${({ page }: LoadingProps) =>
    page &&
    css`
      height: 100%;
    `}
  ${({ content }: LoadingProps) =>
    content &&
    css`
      position: absolute;
      width: 100%;
      height: 100%;
      top: 0;
      left: -24px;
      padding: 0 24px;
      box-sizing: content-box;
      z-index: 100;
      background: rgba(0, 0, 0, 0.5);
    `}
  ${({ parent }: LoadingProps) =>
    parent &&
    css`
      position: absolute;
      width: 100%;
      height: 100%;
      top: 0;
      left: 0;
      z-index: 100;
      background: rgba(0, 0, 0, 0.5);
    `}
`;

const StyledCircularProgress = styled(CircularProgress)`
  color: ${({
    primary,
    secondary,
    theme,
  }: ThemedStyledProps<LoadingProps, IThemeInterface>) =>
    primary
      ? theme.button.primary.color.default
      : secondary
      ? theme.color.secondary
      : theme.button.secondary.color.default} !important;
`;

export const Loading = (props: LoadingProps) => {
  const element = (
    <Wrapper
      page={props.page}
      content={props.content}
      parent={props.parent}
      primary={props.primary}
      secondary={props.secondary}
      className={props.className}
    >
      <StyledCircularProgress
        primary={props.primary}
        secondary={props.secondary}
        size={props.size}
        {...props.options}
      />
      {props.children}
    </Wrapper>
  );
  return props.page ? (
    <Modal open={props.open || true}>{element}</Modal>
  ) : (
    element
  );
};

export default Loading;
