import React, { ReactNode } from 'react';

import Wrapper from './Wrapper';
import Slash from './Slash';
import Link from '../../UI/Link';

export type FooterType = 'default' | 'vp' | 'empty' | 'custom';

export interface FooterProps {
  type?: FooterType;
  customContent?: ReactNode;
}

export const Footer = ({ type, customContent }: FooterProps) => {
  let content: ReactNode = (
    <>
      &copy; Veracity Protocol
      <Slash />
      <Link href="https://www.veracityprotocol.org/tos">
        Terms & Conditions
      </Link>
      <Slash />
      <Link href="https://www.veracityprotocol.org">Contact & Help</Link>
    </>
  );

  switch (type) {
    case 'vp':
      content = <>&copy; Veracity Protocol</>;
      break;
    case 'empty':
      content = <></>;
      break;
    case 'custom':
      content = customContent;
      break;
  }

  return <Wrapper>{content}</Wrapper>;
};

export default Footer;
