import { NodeWithChildren, StyleableComponent } from '../../types';
import React from 'react';
import styled, { css } from 'styles/styled-components';

export type FontSize = 'xs' | 'sm' | 'default' | 'md' | 'lg';

export type Spacing = 'both' | 'top' | 'bottom' | 'none';

export interface PProps extends NodeWithChildren, StyleableComponent {
  fontSize?: FontSize;
  strong?: boolean;
  spacing?: Spacing;
  disabled?: boolean;
}

const marginBottom = css`
  margin-top: 0;
`;

const marginTop = css`
  margin-bottom: 0;
`;

const spacingStyles = css`
  ${({ spacing }: PProps) => {
    switch (spacing) {
      default:
      case 'both':
        break;
      case 'top':
        return marginTop;
      case 'bottom':
        return marginBottom;
      case 'none':
        return [marginTop, marginBottom];
    }
    return undefined;
  }}
`;

const disabledStyles = css`
  opacity: 0.5;
`;

const StyledP = styled.p`
  color: ${({ theme }) => theme.color.light};
  font-weight: ${({ strong }: PProps) => (strong ? 500 : 300)};
  ${({ fontSize }: PProps) =>
    fontSize &&
    css`
      font-size: ${({ theme }) => theme.fontSize[fontSize]};
    `}
  ${spacingStyles}
  ${({ disabled }: PProps) => disabled && disabledStyles}
`;

export const P = (props: PProps) => <StyledP {...props} />;

export default P;
