import styled, { css } from 'styles/styled-components';

import { BannerProps } from './index';

const infoStyle = css`
  color: ${({ theme }) => theme.color.info};
`;

const successStyle = css`
  color: ${({ theme }) => theme.color.success};
`;

const dangerStyle = css`
  color: ${({ theme }) => theme.color.danger};
`;

const message = styled.div`
  font-size: 1em;
  font-weight: bold;
  line-height: 100%;
  white-space: ${({ wrap }: BannerProps) => (wrap ? 'wrap' : 'nowrap')};

  ${({ type }: BannerProps) => {
    switch (type) {
      case 'info':
        return infoStyle;
      case 'success':
        return successStyle;
      case 'danger':
        return dangerStyle;
    }
  }}
`;

export default message;
