import styled from 'styles/styled-components';

const LOGO_SIZE = '48px';

const wrapper = styled.div`
  grid-area: logo;
  display: flex;
  width: ${LOGO_SIZE};
  height: ${LOGO_SIZE};
  justify-content: center;
  align-items: center;
`;

export default wrapper;
